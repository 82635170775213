import "./StockGridCard.scss";
import { Link } from "react-router-dom";

type StockGridCardProps = {
  ticker: string;
  companyName: string;
  sector: string;
  industry: string;
  marketCap: number | string;
  volume: number | string;
  fiftyTwoWeekReturn: number;
  oneMonthReturn: number;
  ltp: number | string;
};

const StockGridCard = (props: StockGridCardProps) => {
  return (
    <Link to={`/aggregatorv2/${props.ticker}`} target="_blank" className="stock-grid-card-link">
      <div className="stock-grid-card">
        <div className="left-pane">
          <div className="ticker">{props.ticker}</div>
          <div className="compnay-name">{props.companyName}</div>
          <div className="sector-industry">
            <div className="sector">Sector : {props.sector}</div>
            <div className="industry">Industry : {props.industry}</div>
          </div>
        </div>
        <div className="right-pane">
          <div className="">MKT CAP (Cr.) </div>
          <div>: {props.marketCap}</div>
          <div className="">VOL (Cr.) </div>
          <div>: {props.volume}</div>
          <div className="">52 W </div>
          <div>
            :{" "}
            <span
              style={{ color: props.fiftyTwoWeekReturn > 0 ? "green" : "red" }}
            >
              {props.fiftyTwoWeekReturn} %
            </span>
          </div>
          <div className="">1 M </div>
          <div>
            :{" "}
            <span style={{ color: props.oneMonthReturn > 0 ? "green" : "red" }}>
              {props.oneMonthReturn} %
            </span>
          </div>
          <div className="">LTP </div>
          <div> : {props.ltp}</div>
        </div>
      </div>
    </Link>
  );
};

export default StockGridCard;
