import { GraphData, FilterData } from "./Home";
import './BacktestingTable.scss';

interface BacktestingTableProps {
  graphData: GraphData | null;
  universeValue: string;
}

const BacktestingTable = ({
  graphData,
  universeValue,
}: BacktestingTableProps) => {
  const getFilteredData = () => {
    if (!graphData) return null;

    let filterData: FilterData | undefined;
    switch (universeValue) {
      case "Steady Performers":
        filterData = graphData["Filter 1 Stocks"];
        break;
      case "Nifty Beaters":
        filterData = graphData["Filter 2 Stocks"];
        break;
      case "Optimized Performers":
        filterData = graphData["Filter 3 Stocks"];
        break;
      default:
        filterData = graphData["Filter 1 Stocks"];
        break;
    }

    const ourStocksParameters: Record<string, unknown> =
      filterData?.["Our Stocks Parameters"] || {};
    const niftybeesParameters: Record<string, unknown> =
      filterData?.["NIFTYBEES Parameters"] || {};

    const mergedParameters = { ...ourStocksParameters, ...niftybeesParameters };

    return Object.keys(mergedParameters).map((key) => {
      return {
        parameter: key,
        ourStocksValue: ourStocksParameters[key],
        niftybeesValue: niftybeesParameters[key],
      };
    });
  };
  const tableData = getFilteredData();
  // console.log(tableData);
  return (
    <div className="backtesting">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>{universeValue}</th>
            <th>Nifty</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((data) => (
            <tr key={data.parameter}>
              <td>{data.parameter}</td>
              <td>{data.ourStocksValue}</td>
              <td>{data.niftybeesValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BacktestingTable;
