import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import messageBlue from "../../assets/images/icons/messageBlue.png";
import progressIcon from "../../assets/images/icons/progressPink.png";
import messageGreen from "../../assets/images/icons/messagebGreen.png";
import clockBlue from "../../assets/images/icons/clockBlue.png";

import Cards from "../../Views/Menu/Cards";
import Overview from "../../Views/Overview/Overview";
import NewOverview from "../../Views/Overview/NewOverview";

import News from "../../Views/News/News";
import CompanyDocs from "../../Views/CompanyDocs/CompanyDocs";
import TechnicalAnalysis from "../../Views/TechnicalAnalysis/TechnicalAnalysis";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";

import { identifyTweet } from "../../Actions/TweetsList";
import { identifyNews } from "../../Actions/NewsList";
import { headerTypes, TabPanelProps } from "./menu.types";
import "./menu.scss";
import FinancialInsights from "../../Views/FinancialInsights/FinancialInsights";
import MutualFunds from "../../Views/MutualFunds/MutualFunds";
import MenuMarquee from "./MenuMarquee";

/**
 * Represents a tab panel used within the Menu component.
 *
 * @param {TabPanelProps} props - The props for the TabPanel component.
 * @returns {JSX.Element} The JSX representation of the TabPanel.
 */
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}
/**
 * Helper function to generate accessibility props for a tab.
 *
 * @param {number} index - The index of the tab.
 * @returns {Object} Accessibility props for the tab.
 */
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/**
 * This React component represents the main menu of the application.
 *
 * The main menu allows users to navigate between different sections of the application,
 * including Overview, Technical Analysis, News, Tweets, Company Docs, and Knowledge Graph.
 *
 * This function is called in \src\Router\Router.tsx
 *
 * @returns {JSX.Element} The JSX representation of the Menu component.
 */
const Menu = () => {
  let { ticker: tickerID, contentID, newsid: newsID, tweetID } = useParams();
  let location = useLocation();
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const tickerQuery = query.get("ticker");

  //console.log(ticker, location);
  const [value, setValue] = React.useState<any>(
    Number(localStorage.getItem("selectedTab"))
      ? Number(localStorage.getItem("selectedTab"))
      : 0
  );

  const navigateMenu = useSelector((state: any) => {
    console.log("STATE", state.NavigateMenu);
    return state.NavigateMenu;
  });

  useLayoutEffect(() => {
    setValue(
      Number(localStorage.getItem("selectedTab"))
        ? Number(localStorage.getItem("selectedTab"))
        : navigateMenu
    );
  }, [navigateMenu]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    localStorage.setItem("selectedTab", newValue.toString());
    setValue(newValue);
  };

  const [headerInf, setHeaderInf] = useState<headerTypes>({} as headerTypes);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  //Redirecting routes to the respective tabs
  const getHeaderData = () => {
    let tickerId = tickerQuery ? tickerQuery : tickerID
      ? tickerID
      : window.localStorage.getItem("tickerId");
    if (location.pathname.toLowerCase().includes("news")) {
      setValue(2);
    } else if (location.pathname.toLowerCase().includes("tweets")) {
      setValue(3);
    } else if (location.pathname.toLowerCase().includes("technicalanalysis")) {
      setValue(1);
    }

    setIsLoaderVisible(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${tickerId}/header_information.json`
      )
      .then(
        (response) => {
          setIsLoaderVisible(false);
          setHeaderInf(response.data);
        },
        (error) => {
          setIsLoaderVisible(false);
          console.log(error);
        }
      );
  };

  useEffect(() => {
    getHeaderData();
  }, []);

  useEffect(() => {
    if (value !== 3) {
      dispatch(identifyNews([]));
    } else if (value !== 4) {
      dispatch(identifyTweet([]));
    }
  }, [value]);

  const { width }: any = useWindowDimemsions();
  const lgWidth = 991;

  const styleObj =
    width && width > lgWidth ? { fontSize: "14px" } : { fontSize: "13px" };

  const tabFontSize =
    width && width >= lgWidth
      ? { fontSize: "15px" }
      : width && width >= 685 && width < lgWidth
      ? { fontSize: "14px" }
      : { fontSize: "13px" };

  return (
    <div className="main-menu">
      <div className="main-menu-inner mt-4 mb-4">
        <div className="main-menu-header">
          <div>
          <h4 className="bank-name" style={styleObj}>
            {headerInf.Stock_Name}
          </h4>
            <h6 className="bank-branch me-2 mb-0" style={styleObj}>
              {headerInf.Stock_Symbol}
            </h6>{" "}
          </div>
            <span className="finance" style={styleObj}>
              {headerInf.Stock_Sector}
            </span>
        </div>
        <MenuMarquee
        text1={
          " Previous Close : " + headerInf.Yesterdays_Close
          
        }
        text2={
          " Open : " + headerInf.Todays_Open
        }
        text3={
          " Last Traded Price : " + headerInf.Todays_Close
        }
        text4={
          " News Last Updated : " + moment(headerInf.Last_Updated).format(
            "DD-MMM-YYYY HH:mm "
          )
        }
        text5={
          " Stocks Last Updated : " + moment(headerInf.Last_Updated_Stocks).format(
            "DD-MMM-YYYY HH:mm "
          )
        }
      />
        <div className="main-menu-tabs">
          <div className="tab-content">
            <Cards
              image={messageBlue}
              heading={
                headerInf.Yesterdays_Close === "Data not Present"
                  ? "NA"
                  : headerInf.Yesterdays_Close
              }
              status={"Previous Close"}
            />
          </div>
          <div
            className="tab-content">
            <Cards
              image={progressIcon}
              heading={
                headerInf.Todays_Open === "Data not Present"
                  ? "NA"
                  : headerInf.Todays_Open
              }
              status={"Open"}
            />
          </div>
          <div className="tab-content">
            <Cards
              image={messageGreen}
              heading={
                headerInf.Todays_Close === "Data not Present"
                  ? "NA"
                  : headerInf.Todays_Close
              }
              status={"Last Traded Price"}
            />
          </div>
          <div className="tab-content">
            <Cards
              image={clockBlue}
              heading={moment(headerInf.Last_Updated).format(
                "DD-MMM-YYYY HH:mm "
              )}
              status={"News Last Updated"}
            />
          </div>
          <div className="tab-content">
            <Cards
              image={clockBlue}
              heading={moment(headerInf.Last_Updated_Stocks).format(
                "DD-MMM-YYYY HH:mm "
              )}
              status={"Stocks Last Updated"}
            />
          </div>
        </div>
        {/* <div className="row align-items-center">
          <div className="col " style={{ maxWidth: "268px" }}>
            <h4 className="bank-name" style={styleObj}>
              {headerInf.Stock_Name}
            </h4>
            <div className="d-flex flex-column">
              <h6 className="bank-branch me-2 mb-0" style={styleObj}>
                {headerInf.Stock_Symbol}
              </h6>{" "}
              <span className="finance" style={styleObj}>
                {headerInf.Stock_Sector}
              </span>
            </div>
          </div>

          <div
            className="col text-center mt-3"
            style={{
              maxWidth: "225px",
              minWidth: "160px",
            }}
          >
            <Cards
              image={messageBlue}
              heading={
                headerInf.Yesterdays_Close === "Data not Present"
                  ? "NA"
                  : headerInf.Yesterdays_Close
              }
              status={"Previous Close"}
            />
          </div>
          <div
            className="col text-center mt-3"
            style={{
              maxWidth: "225px",
              minWidth: "160px",
            }}
          >
            <Cards
              image={progressIcon}
              heading={
                headerInf.Todays_Open === "Data not Present"
                  ? "NA"
                  : headerInf.Todays_Open
              }
              status={"Open"}
            />
          </div>

          <div
            className="col text-center mt-3"
            style={{
              maxWidth: "225px",
              minWidth: "160px",
            }}
          >
            <Cards
              image={messageGreen}
              heading={
                headerInf.Todays_Close === "Data not Present"
                  ? "NA"
                  : headerInf.Todays_Close
              }
              status={"Last Traded Price"}
            />
          </div>

          <div
            className="col text-center mt-3"
            style={{
              maxWidth: width && width > lgWidth ? "265px" : "225px",
              //minWidth: '160px'
            }}
          >
            <Cards
              image={clockBlue}
              heading={moment(headerInf.Last_Updated).format(
                "DD-MMM-YYYY HH:mm "
              )}
              //  status={"News/Tweets Last Updated"}
              status={"News Last Updated"}
            />
          </div>

          <div
            className="col text-center mt-3"
            style={{
              maxWidth: width && width > lgWidth ? "265px" : "225px",
              //  minWidth: '163px'
              // minWidth: '160px'
            }}
          >
            <Cards
              image={clockBlue}
              heading={moment(headerInf.Last_Updated_Stocks).format(
                "DD-MMM-YYYY HH:mm "
              )}
              status={"Stocks Last Updated"}
            />
          </div>
        </div> */}
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs
            variant={width && width > 718 ? "standard" : "scrollable"}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
          >
            <Tab
              className="Tab-custom-style"
              label="Overview"
              {...a11yProps(0)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}
            />
            {/* <Tab
              className="Tab-custom-style"
              label="newOverview"
              {...a11yProps(0)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}  /> */}
          
            {/* <Tab
              label="Prediction"
              className="Tab-custom-style"
              {...a11yProps(1)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "16px",
                maxHeight: "1rem",
                minHeight: "37px",
              }}
            /> */}
            <Tab
              className="Tab-custom-style"
              label="Technical Analysis"
              {...a11yProps(1)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}
            />

            <Tab
              className="Tab-custom-style"
              label="News"
              {...a11yProps(2)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}
            />

            {/* <Tab
              label="Tweets"
                          className="Tab-custom-style"

              {...a11yProps(3)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize
              }}
            /> */}
            <Tab
              //label="Company Docs"
              label="Company Reports"
              className="Tab-custom-style"
              {...a11yProps(4)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}
            />
            <Tab
              label="Mutual Funds"
              className="Tab-custom-style"
              {...a11yProps(5)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}
              
            />
             {/* <Tab
              className="Tab-custom-style"
              label="New Overview"
              {...a11yProps(6)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                maxHeight: "1rem",
                minHeight: "37px",
                ...tabFontSize,
              }}
            /> */}
            {/* <Tab
              label="Knowledge Graph"
                          className="Tab-custom-style"

              {...a11yProps(5)}
              style={{
                color: "black",
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "16px",
                maxHeight: "1rem",
                minHeight: "37px",
              }}
            /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <NewOverview activeTab={setValue} stock_name={headerInf.Stock_Name}/>
          {/* <Overview activeTab={setValue} stock_name={headerInf.Stock_Name} /> */}
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <Prediction />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <TechnicalAnalysis />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <News tickerid={tickerID || ""} news_id={parseInt(newsID || "")} />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <Tweets
            tickerid={tickerid}
            content_id={contentid}
            tweet_id={parseInt(tweet_id)}
          />
        </TabPanel> */}
        <TabPanel value={value} index={3}>
          {/* <CompanyDocs /> */}
          <FinancialInsights />
        </TabPanel>

        {/* <TabPanel value={value} index={6}>
          <KnowledgeGraph />
        </TabPanel> */}
        <TabPanel value={value} index={4}>
          <MutualFunds />
        </TabPanel>
        {/* <TabPanel value={value} index={5}>
          <NewOverview activeTab={setValue} stock_name={headerInf.Stock_Name}/>
          
        </TabPanel> */}
      </Box>

      {isLoaderVisible && (
        <div className="loader">
          {" "}
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Menu;
