import { AutoComplete } from "antd";

import "./TickerFilter.scss";

type TickerFilterProps = {
  allTickers: string[];
  onChange: (value: string) => void;
};

const TickerFilter = (props: TickerFilterProps) => {
  const options = props.allTickers.map((ticker) => {
    return { value: ticker };
  });

  return (
    <div className="ticker-filter">
      <AutoComplete
        style={{ width: "100%" }}
        placeholder="Filter by Ticker"
        allowClear={true}
        defaultActiveFirstOption={false}
        options={options}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={props.onChange}
      />
    </div>
  );
};

export default TickerFilter;
