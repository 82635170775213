import React, { useState } from "react";
import { StockSelectionTableData } from "../../Pages/StockSelectionPage";
// import "./ShowcaseTable.scss";

type ShowcaseTableProps = {
  tableData: StockSelectionTableData[] | null;
};

const ShowcaseTable = ({ tableData }: ShowcaseTableProps) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof StockSelectionTableData | null;
    direction: "ascending" | "descending";
  }>({ key: null, direction: "ascending" });

  const sortedData = React.useMemo(() => {
    if (!tableData || !sortConfig.key) return tableData;

    return [...tableData].sort((a, b) => {
      if (a[sortConfig.key!] < b[sortConfig.key!]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key!] > b[sortConfig.key!]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [tableData, sortConfig]);

  const requestSort = (key: keyof StockSelectionTableData) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="stock-selection-showcase">
      <div className="heading">
        <h3>Showcasing Stocks that outperformed its benchmark in last 5 years</h3>
        <p>
          Showing <span>{tableData?.length}</span> Enteries
        </p>
      </div>
      <div className="datatable">
        <table className="table table-striped">
          <thead>
            <tr>
              <th onClick={() => requestSort("Stock")}>
                Stock
                <span>
                  {sortConfig.key === "Stock" && (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
                </span>
              </th>
              <th onClick={() => requestSort("Sector")}>
                Sector
                <span>
                  {sortConfig.key === "Sector" && (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
                </span>
              </th>
              <th onClick={() => requestSort("Industry")}>
                Industry
                <span>
                  {sortConfig.key === "Industry" && (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
                </span>
              </th>
              <th>5 Day Avg Volume (Cr)</th>
              <th>1 Year Return</th>
              <th onClick={() => requestSort("Difference_wrt_Nifty")}>
                Difference wrt Nifty
                <span>
                  {sortConfig.key === "Difference_wrt_Nifty" &&
                    (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
                </span>
              </th>
              <th>Index Satisfied</th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.map((item, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    (item.Difference_wrt_Nifty) > 0 ? "#D5F3C8" : "#F8D7DA",
                }}
              >
                <td>{item.Stock}</td>
                <td>{item.Sector}</td>
                <td>{item.Industry}</td>
                <td>{item["5 Day Avg Volume (Cr)"].toFixed(2)}</td>
                <td>{item["1_Year_Return"]}</td>
                <td>{item.Difference_wrt_Nifty.toFixed(2)}</td>
                <td>
                  {item["Index Satisfied"]
                    .replace("[", "")
                    .replace("]", "")
                    .replace(/'/g, "")
                    .split(", ")
                    .join(", ")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShowcaseTable;
