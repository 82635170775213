import { useEffect, useState } from "react";
import Navbar from "../../Component/LandingV2/Navbar";
import SubNavbar, { NavItem } from "../../Component/LandingV2/SubNavbar";
import NewsAggregatorV2 from "./NewsAggregatorV2";
import PeerComparison from "./PeerComparison";
import TechnicalIndicators from "./TechnicalIndicators";
import NoResultsBox from "../Common/NoResultsBox/NoResultsBox";

interface ComparisonTable {
  [metric: string]: {
    [company: string]: number;
  };
}

interface PeerPercentChangeGraphCoordinates {
  [company: string]: {
    [date: string]: number;
  };
}

interface TechnicalIndicatorsData {
    [company: string]: {
      [date: string]: number;
    };
}

export interface ComparisonData {
  peer_comparison_data: {
    comparison_table: ComparisonTable;
    peer_percentChange_graph_coordinates: PeerPercentChangeGraphCoordinates;
  };
  techincal_indicators_data: {
    RSI_line: TechnicalIndicatorsData;
    SMA_line: TechnicalIndicatorsData;
    "Close * Volume__bar": TechnicalIndicatorsData;
    ADX_bar: TechnicalIndicatorsData;
    "Williams %R_line": TechnicalIndicatorsData;
    "Standard Deviation_line": TechnicalIndicatorsData;
  }
}
 export enum subNavTypes {
  NewsAggregator = "News Aggregator",
  PeerComparison = "Peer Comparison",
  TechnicalIndicators = "Technical Indicators",
 }

const AggregatorV2 = () => {

  // get the ticker
  const ticker = window.location.pathname.split("/")[2];
  // console.log("Ticker", ticker);
  // React state
  const [comparisonData, setComparisonData] = useState<
  ComparisonData | undefined
  >(undefined);

  const [subNavActive, setSubNavActive] = useState<subNavTypes>(subNavTypes.NewsAggregator);

  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

  // Static data
  const subNavData: NavItem[] = [
    {
      name: "News Aggregator",
      triggerFunction: () => setSubNavActive(subNavTypes.NewsAggregator),
      isActive: subNavActive === subNavTypes.NewsAggregator,
    },
    {
      name: "Peer Comparison",
      triggerFunction: () => setSubNavActive(subNavTypes.PeerComparison),
      isActive: subNavActive === subNavTypes.PeerComparison,
    },
    {
      name: "Technical Indicators",
      triggerFunction: () => setSubNavActive(subNavTypes.TechnicalIndicators),
      isActive: subNavActive === subNavTypes.TechnicalIndicators,
    },
  ];

  const fetchComparisonData = async () => {
    try {
      setIsLoaderVisible(true)
      const response = await fetch(
        `${process.env.REACT_APP_STOCK_SELECTION}/${ticker}/peer_comparison.json`
        // `${process.env.REACT_APP_STOCK_SELECTION}/MSTCLTD.NS/peer_comparison.json`
      );
      console.log(ticker)
      const data = await response.json();
      console.log("Comparison data fetched successfully", data);
      setComparisonData(data);
      // return data;
    } catch (error) {
      console.error("Error fetching comparison data", error);
    } finally {
      setIsLoaderVisible(false)
    }
  };

  useEffect(() => {
    fetchComparisonData();
  }, []);

  // Loading State
  if (!comparisonData) {
    return (
      <div>
        <Navbar />
        <SubNavbar navData={subNavData} />
                <div
                  className=" row "
                  style={{
                    background: "#fff",
                    borderRadius: "5px",
                    padding: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                  }}
                >
                  <div className="col">
                    <div
                      style={{
                        minHeight: "80svh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <NoResultsBox
                        displayText={
                          isLoaderVisible ? `searching...` : `No results`
                        }
                        showEmptyImage={true}
                      />
                    </div>
                  </div>
                </div>
      </div>
    );
  }

  // component map
  const componentMap = {
    [subNavTypes.NewsAggregator]: <NewsAggregatorV2 comparisonData={comparisonData} />,
    [subNavTypes.PeerComparison]: <PeerComparison comparisonData={comparisonData} />,
    [subNavTypes.TechnicalIndicators]: <TechnicalIndicators comparisonData={comparisonData} />,
  };

  return (
    <div>
      <Navbar />
      <SubNavbar navData={subNavData} />
      {componentMap[subNavActive]}

      {/* <NewsAggregatorV2 comparisonData={comparisonData} /> */}
      {/* <PeerComparison comparisonData={comparisonData} /> */}
      {/* <TechnicalIndicators comparisonData={comparisonData} /> */}
    </div>
  );
};
export default AggregatorV2;
