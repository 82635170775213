import React, { useState, useRef, useEffect } from "react";
// import { ChevronDownIcon } from 'lucide-react'

import "./StockSelectionDropdown.scss";

type StockSelectionDropdownProps = {
  stocks: { value: string; label: string }[];
};

function StockSelectionDropdown({ stocks }: StockSelectionDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState<(typeof stocks)[0] | null>(
    null
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="stock-select" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="stock-select-button"
      >
        <span className="stock-select-text">
          {selectedStock ? selectedStock.label : "Search Ticker Name"}
        </span>
        <span className="stock-select-icon">
          {/* <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" /> */}
        </span>
      </button>
      {isOpen && (
        <div className="stock-options">
          <ul className="py-1">
            {stocks.map((stock) => (
              <li
                key={stock.value}
                onClick={() => {
                  setSelectedStock(stock);
                  setIsOpen(false);
                  console.log("Selected stock: ", stock.value);
                  // e.preventDefault();
                  //redirect to /detail?ticker=XYZ
                  const ticker = stock.value;
                  if (ticker) {
                    // window.location.href = `/detail?ticker=${ticker}`;
                    window.localStorage.setItem("selectedTab", "0");
                    window.localStorage.setItem("tickerId", ticker);
                    window.location.href = `/detail`;
                  }
                }}
                // className="px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 cursor-pointer"
              >
                {stock.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default StockSelectionDropdown;
