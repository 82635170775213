import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import Navbar from "../Component/LandingV2/Navbar";
import MiniFooter from "../Component/LandingV2/MiniFooter";
import PriceCard from "../Component/Trending/PriceCard";
import TrendingEventCard from "../Component/Trending/TrendingEventCard";
import TickerFilter from "../Component/Trending/TickerFilter";

import "./TrendingPage.scss";

import { AlertData, AlertsJson } from "../types/alerts.json";
import { Link } from "react-router-dom";

const highlightBoxEnum = {
  PriceChange: 0,
  OpenPrices: 1,
  LastTradedPrice: 2,
  HotEvents: 3,
  NewsCount: 4,
  NewsStories: 5,
  FilterTicker: 6,
};

const infoBoxText = {
  [highlightBoxEnum.PriceChange]:
    " It indicates the percentage increase 📈 or decrease 📉 in the stock price relative to the previous day's closing price. 📊",
  [highlightBoxEnum.OpenPrices]:
    "New Value displayed in green indicates highest ⬆️ opening price over the last 15days with corresponding second highest Old value similarly value in red indicates lowest ⬇️ opening price over the same period with corresponding second lowest Old value.",
  [highlightBoxEnum.LastTradedPrice]:
    "New Value displayed in green indicates that last traded price is highest ⬆️ as compared to day's close over the last 15 days with corresponding second highest Old value. Similarly value in red indicates that the price is lowest ⬇️ over the same period with corresponding second lowest Old value.",
  [highlightBoxEnum.HotEvents]:
    "Provides a summary for each company’s most significant events. 📚",
  [highlightBoxEnum.NewsCount]:
    "This tab provides insights into a company by showcasing the number of news articles published in the last 24 hours and its weekly news average. 🗞️",
  [highlightBoxEnum.NewsStories]:
    `The "Top Stories" section highlights significant news where companies are prominently featured. Articles qualify as a top story if the corresponding company is mentioned or referenced in more than five sentences.`,
  [highlightBoxEnum.FilterTicker]:
    "Select a Ticker name of your choice to receive filtered results 🔎"
};

const Trending = () => {

  const [data, setData] = useState<AlertData | null>(null);
  const [tickerFilter, setTickerFilter] = useState<string | null>(null);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [highlightBox, sethighlightBox] = useState(
    highlightBoxEnum.PriceChange
  );
  // const cardRef = useRef<HTMLDivElement>(null);
  const infoBoxRefList = useRef<(HTMLDivElement | null)[]>([]);

  const setInfoBoxRef = (index: number) => (ref: HTMLDivElement | null) => {
    infoBoxRefList.current[index] = ref;
  }

  const toggleInfoBox = () => {
    setShowInfoBox((prev) => !prev); // Show the info-box
    sethighlightBox(highlightBoxEnum.PriceChange); // Reset the highlight box
  };

  useEffect(() => {
    if (showInfoBox && infoBoxRefList.current) {
      const cardRef = infoBoxRefList.current[highlightBox];
      if (!cardRef) {
        console.log("Could not get bounding ref");
        return;
      }
      const cardRect = cardRef.getBoundingClientRect();
      const infoBox = document.getElementById("infoBox");
  if (infoBox) {
    const infoBoxWidth = infoBox.offsetWidth;
    const viewportWidth = window.innerWidth; 
    let leftPosition = cardRect.right + window.scrollX + 20;
    let topPosition = cardRect.top + window.scrollY;
    if (viewportWidth < 400) {
        if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = viewportWidth - infoBoxWidth - 40; 
        }
        infoBox.style.width = '135px'; 
        infoBox.style.fontSize = '12px';
        topPosition = cardRect.bottom + window.scrollY + 5 ;
    } else if (viewportWidth < 768) {
        if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = viewportWidth - infoBoxWidth - 40;
        }
        infoBox.style.width = '135px'; 
        infoBox.style.fontSize = '12px';
        topPosition = cardRect.bottom + window.scrollY + 5  ;      
    } else {
        if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = cardRect.left - infoBoxWidth - 5;
        }
        infoBox.style.width = '';
        infoBox.style.fontSize = '';
    }
    infoBox.style.top = `${topPosition}px`;
    infoBox.style.left = `${leftPosition}px`;
    infoBox.style.zIndex = '25';  
}
  }
  }, [showInfoBox, highlightBox]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_API_URL || "") + "/Alerts/alerts.json"
      );
      const data: AlertsJson = await response.json();

      // Find latest date
      const latestDate = Object.keys(data).sort().reverse()[0];

      setData({ ...data[latestDate], date: latestDate });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Loading state
  if (!data) {
    return (
      <>
        <Navbar />
        <div className="loading">Loading...</div>
      </>
    );
  }

  // Create list of open highest difference
  // List of highest
  const combinedOpenValues = Object.keys(
    data.price_change_alerts.alerts_open_highest
  ).map((key) => {
    return {
      ticker: key,
      ...data.price_change_alerts.alerts_open_highest[key],
      minmax: data.price_change_alerts.alerts_open_highest[key].max_open || 0,
    };
  });
  // Concat lowest
  combinedOpenValues.push(
    ...Object.keys(data.price_change_alerts.alerts_open_lowest).map((key) => {
      return {
        ticker: key,
        ...data.price_change_alerts.alerts_open_lowest[key],
        minmax: data.price_change_alerts.alerts_open_lowest[key].min_open || 0,
      };
    })
  );

  // Sort by difference between live day and minmax
  combinedOpenValues
    .sort((a, b) => {
      return (
        Math.abs(a.live_day_open - a.minmax) -
        Math.abs(b.live_day_open - b.minmax)
      );
    })
    .reverse();

  // console.log(combinedOpenValues);

  // Create list of close highest difference
  // List of highest
  const combinedCloseValues = Object.keys(
    data.price_change_alerts.alerts_close_highest
  ).map((key) => {
    return {
      ticker: key,
      ...data.price_change_alerts.alerts_close_highest[key],
      minmax: data.price_change_alerts.alerts_close_highest[key].max_close || 0,
    };
  });
  // Concat lowest
  combinedCloseValues.push(
    ...Object.keys(data.price_change_alerts.alerts_close_lowest).map((key) => {
      return {
        ticker: key,
        ...data.price_change_alerts.alerts_close_lowest[key],
        minmax:
          data.price_change_alerts.alerts_close_lowest[key].min_close || 0,
      };
    })
  );

  // Sort by difference
  combinedCloseValues
    .sort((a, b) => {
      return (
        Math.abs(a.live_day_close - a.minmax) -
        Math.abs(b.live_day_close - b.minmax)
      );
    })
    .reverse();

  // console.log(combinedCloseValues);

  // List of all unique tickers for filter
  // Sort alphabetically
  const tickerList = [
    ...new Set([
      ...Object.keys(data.price_change_alerts.top_n_price_change.stock_symbol),
      ...Object.values(data.price_change_alerts.alerts_open_highest).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts.alerts_open_lowest).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts.alerts_close_highest).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts.alerts_close_lowest).map(
        (ele) => ele.ticker_symbol
      ),
    ]),
  ].sort();

  // console.log(tickerFilter, tickerList);

  return (
    <>
      <Navbar />
      <section className="trending-section">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="link" onClick={toggleInfoBox} >
          What's this?
        </a>
        {showInfoBox && (
          <>
            <div id="infoBox" className="info-box">
              {/* Your info-box content here */}
              <div className="info-box-content">
                <p>{infoBoxText[highlightBox]}</p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    width={18}
                    height={18}
                    onClick={() => setShowInfoBox(false)}
                  >
                    <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                  </svg>
                </div>
              </div>
              <div className="info-box-bottom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    fill="#fe5f55"
                    fillOpacity={0.8}
                    width={10}
                    height="1.5rem"
                    onClick={() =>
                      sethighlightBox((prev) => (prev - 1 < 0 ? 0 : prev - 1))
                    }
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                  </svg>
                  <span>{highlightBox + 1}/7</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    fill="#fe5f55"
                    fillOpacity={0.8}
                    width={10}
                    height="1.5rem"
                    onClick={() =>
                      sethighlightBox((prev) =>
                        prev + 1 > Object.keys(highlightBoxEnum).length
                          ? 0
                          : prev + 1
                      )
                    }
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                </div>
              
            </div>
            <div
              className="modal-backdrop"
              // onClick={() => setShowInfoBox(false)}
            ></div>
          </>
        )}
        <div className="grey-box">
          <div className="three-pane-split">
            <div className="price-pane">
              {/* Ticker filter Mobile view only */}
              <div className="only-mobile">
                <TickerFilter
                  allTickers={tickerList}
                  onChange={(value) => {
                    setTickerFilter(value);
                  }}
                />
              </div>
              {/* Price Change card */}
              <PriceCard
                setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.PriceChange)}
                toggleInfoBox={toggleInfoBox}
                highlightBox={
                  showInfoBox && highlightBox === highlightBoxEnum.PriceChange
                    ? true
                    : false
                }
                title={
                  <>
                    <span className="price-change-title">Price Change</span>
                    <Tooltip
                      anchorSelect=".price-change-title"
                      place="top"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: 400,
                      }}
                    >
                      Percent of change from previous day <br />
                      Sorted by percent change
                    </Tooltip>
                  </>
                }
                headers={[
                  "Stock Ticker",
                  <>
                    <div className="price-change-value-header">
                      Value (% Change)
                    </div>
                    <Tooltip anchorSelect=".price-change-value-header">
                      Percent of change from previous day
                    </Tooltip>
                  </>,
                ]}
                values={Object.keys(
                  data.price_change_alerts.top_n_price_change.stock_symbol
                )
                  // Filter by ticker
                  .filter((key) => {
                    if (!tickerFilter) return true;
                    return key === tickerFilter;
                  })
                  .map((key, i) => {
                    const isRed =
                      data.price_change_alerts.top_n_price_change.stock_symbol[
                        key
                      ] < 0;
                    return [
                      key,
                      <div
                        className={isRed ? "text-red" : "text-green"}
                        key={i}
                      >
                        {data.price_change_alerts.top_n_price_change.stock_symbol[
                          key
                        ]
                          .toPrecision(3)
                          .toString()}
                        {isRed ? <RedDownArrow /> : <GreenUpArrow />}
                      </div>,
                    ];
                  })
                  .flat()}
              />
              {/* Open Price Card */}
              <PriceCard
                toggleInfoBox={toggleInfoBox}
                setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.OpenPrices)}
                highlightBox={
                  showInfoBox && highlightBox === highlightBoxEnum.OpenPrices
                    ? true
                    : false
                }
                title={
                  <>
                    <span className="open-price-title">Open Prices</span>
                    <Tooltip
                      anchorSelect=".open-price-title"
                      place="top"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: 400,
                      }}
                    >
                      Identifies the highest and lowest open prices in the last
                      15 days
                    </Tooltip>
                  </>
                }
                headers={[
                  "Stock Ticker",
                  <>
                    <div className="open-price-value-header">New Value*</div>
                    <Tooltip anchorSelect=".open-price-value-header">
                      New value of the stock
                    </Tooltip>
                  </>,
                  <>
                    <div className="open-price-minmax-header">Old Value</div>
                    <Tooltip anchorSelect=".open-price-minmax-header">
                      Old value of the stock
                    </Tooltip>
                  </>,
                ]}
                values={combinedOpenValues
                  // Filter by ticker
                  .filter((key) => {
                    if (!tickerFilter) return true;
                    return key.ticker_symbol === tickerFilter;
                  })
                  .map((value, i) => {
                    const isRed = value.min_open !== undefined;
                    return [
                      <div key={`${i}-ticker`}> {value.ticker_symbol} </div>,
                      <div
                        className={isRed ? "text-red" : "text-green"}
                        key={`${i}-value`}
                      >
                        {value.live_day_open.toString()}
                      </div>,
                      <div key={`${i}-minmax`}>
                        {" "}
                        {value.minmax.toString()}{" "}
                      </div>,
                    ];
                  })
                  .flat()}
              />
              {/* Close Prices Card */}
              <PriceCard
                toggleInfoBox={toggleInfoBox}
                setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.LastTradedPrice)}
                highlightBox={
                  showInfoBox && highlightBox === highlightBoxEnum.LastTradedPrice
                    ? true
                    : false
                }
                title={
                  <>
                    <span className="close-price-title">Close Prices</span>
                    <Tooltip
                      anchorSelect=".close-price-title"
                      place="top"
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: 400,
                      }}
                    >
                      Identifies the highest and lowest close prices in the last
                      15 days
                    </Tooltip>
                  </>
                }
                headers={[
                  "Stock Ticker",
                  <>
                    <div className="open-price-value-header">New Value*</div>
                    <Tooltip anchorSelect=".open-price-value-header">
                      New value of the stock
                    </Tooltip>
                  </>,
                  <>
                    <div className="open-price-minmax-header">Old Value</div>
                    <Tooltip anchorSelect=".open-price-minmax-header">
                      Old value of the stock
                    </Tooltip>
                  </>,
                ]}
                values={combinedCloseValues
                  // Filter by ticker
                  .filter((key) => {
                    if (!tickerFilter) return true;
                    return key.ticker_symbol === tickerFilter;
                  })
                  .map((value, i) => {
                    const isRed = value.min_close !== undefined;
                    return [
                      <div key={`${i}-ticker`}> {value.ticker_symbol} </div>,
                      <div
                        className={isRed ? "text-red" : "text-green"}
                        key={`${i}-value`}
                      >
                        {value.live_day_close.toString()}
                      </div>,
                      <div key={`${i}-minmax`}>
                        {" "}
                        {value.minmax.toString()}{" "}
                      </div>,
                    ];
                  })
                  .flat()}
              />
            </div>
            <div
              className="events-pane"
              style={
                highlightBox === highlightBoxEnum.HotEvents
                  ? { zIndex: 20 }
                  : {}
              }
              ref={(ref) =>
                (infoBoxRefList.current[highlightBoxEnum.HotEvents] = ref)
              }
            >
              <>
                <h3 className="title hot-event-title">Hot Events</h3>
                <Tooltip anchorSelect=".hot-event-title" place="top">
                  Identifies top news events and gathers related news data to
                  provide a summary for each company's most important event.
                </Tooltip>
              </>
              <div className="events-position">
                <div className="events-flex">
                  {Object.keys(data.events_with_summary)
                    // Filter by ticker
                    .filter((key) => {
                      if (!tickerFilter) return true;
                      return (
                        data.events_with_summary[key].stock_symbol ===
                        tickerFilter
                      );
                    })
                    .map((key, i) => (
                      <TrendingEventCard
                        key={i}
                        title={`${key}`}
                        ticker={data.events_with_summary[key].stock_symbol}
                        events={[
                          {
                            title: data.events_with_summary[key].max_event,
                            subtitle: data.events_with_summary[key].summary,
                          },
                        ]}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="news-stories-pane">
              {/* Ticker filter Desktop view */}
              <div
                className="only-desktop"
                style={
                  highlightBox === highlightBoxEnum.FilterTicker
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.FilterTicker] = ref)
                }
              >
                <TickerFilter
                  allTickers={tickerList}
                  onChange={(value) => {
                    setTickerFilter(value);
                  }}
                />
              </div>

              {/* News Count card */}
              <div
                className="news-count-card"
                style={
                  highlightBox === highlightBoxEnum.NewsCount
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.NewsCount] = ref)
                }
              >
                <>
                  <h3 className="title news-count-title">News Count</h3>
                  <Tooltip anchorSelect=".news-count-title" place="top">
                    Identifies the number of news articles published in the last
                    24 hours and the weekly average.
                  </Tooltip>
                </>
                <div className="news-list">
                  {data?.news_count?.all_companies && Object?.keys(data.news_count.all_companies)
                    ?.sort()
                    // Filter by ticker
                    .filter((key) => {
                      if (!tickerFilter) return true;
                      return (
                        data.news_count.all_companies[key].stock_symbol ===
                        tickerFilter
                      );
                    })
                    .map((key, i) => (
                      <div className="news" key={i}>
                        <Link
                          to={`/news/${
                            data.news_count.all_companies[key].stock_symbol ||
                            key
                          }`}
                        >
                          <h4 className="ticker">{`${
                            data.news_count.all_companies[key].stock_symbol ||
                            key
                          }`}</h4>
                        </Link>
                        <div className="count-details">
                          <span style={{ color: "green", fontWeight: 700 }}>
                            {data.news_count.all_companies[key].news_count}{" "}
                          </span>
                          news in the last 24 hours, the weekly average was{" "}
                          {data.news_count.all_companies[key].avg_7days_news}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* Top Stories Card */}
              <div
                className="top-stories-card"
                style={
                  highlightBox === highlightBoxEnum.NewsStories
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.NewsStories] = ref)
                }
              >
                <>
                  <h3 className="title top-stories-title">Top Stories</h3>
                  <Tooltip anchorSelect=".top-stories-title" place="top">
                  "Top Stories" features news with the corresponding company mentioned over five times.
                  </Tooltip>
                </>
                <div className="stories-list">
                  {Object.keys(data.Top_stories_using_TSA_count)
                    // Filter by ticker
                    .filter((key) => {
                      if (!tickerFilter) return true;
                      return (
                        data.Top_stories_using_TSA_count[key].stock_symbol ===
                        tickerFilter
                      );
                    })
                    .map((key, i) => (
                      <a
                        className="story"
                        key={i}
                        href={data.Top_stories_using_TSA_count[key].link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h4 className="date-time">
                          {data.Top_stories_using_TSA_count[key].news_date}
                        </h4>
                        <div className="story-title">
                          {data.Top_stories_using_TSA_count[key].title}
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer disclaimer */}
        <div className="footer-disclaimer">
          <div className="disclaimer">
            *values are the highest/lowest values in last 15 days. In New Value
            column, “<span style={{ color: "red" }}>Red</span>” color signifies
            lowest where as “<span style={{ color: "green" }}>Green</span>”
            stands for highest
          </div>
          {/* updated */}
          <div className="updated">Updated on : {data.date}</div>
        </div>
      </section>

      <MiniFooter />
    </>
  );
};

const RedDownArrow = () => (
  <svg
    style={{
      height: "1rem",
      aspectRatio: "1/1",
      marginLeft: "0.5rem",
    }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 18"
    fill="#ED0000"
  >
    <path
      d="M7.57637 17.2019L0.583213 0.417997L14.5695 0.417996L7.57637 17.2019Z"
      fill="#ED0000"
    />
  </svg>
);

const GreenUpArrow = () => (
  <svg
    style={{
      height: "1rem",
      aspectRatio: "1/1",
      marginLeft: "0.5rem",
    }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 18"
    fill="#2C8745"
  >
    <path
      d="M7.57598 0.908096L14.5691 17.692L0.582821 17.692L7.57598 0.908096Z"
      fill="#2C8745"
    />
  </svg>
);

export default Trending;
