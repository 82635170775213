import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

interface FundData {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

interface Dataset {
  label: string;
  data: (number | null)[];
  borderColor: string;
  backgroundColor: string;
  fill: boolean;
}

interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

interface MutualFundAlertProps {
  mutualFundsAlerts: FundData;
}

const getRandomColor = (): string => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const processData = (data: FundData): { chartData: ChartData; alerts: string[] } => {
  const mutualFunds = Object.keys(data);

  // Collect all unique dates
  const allDates = new Set<string>();
  mutualFunds.forEach((fund) => {
    Object.keys(data[fund]).forEach((date) => allDates.add(date));
  });
  const sortedDates = Array.from(allDates).sort();

  // const sectorNames = Object.keys(Object.values(data[mutualFunds[0]])[0]);
  const sectorNames = mutualFunds[0] && data[mutualFunds[0]] ? Object.keys(Object.values(data[mutualFunds[0]])[0]): [];

  const alerts: string[] = [];

  // Create datasets for each mutual fund
  const datasets: Dataset[] = mutualFunds.map((fund) => {
    const values: (number | null)[] = new Array(sortedDates.length).fill(null);
    let lastChangeDate: string | null = null;
    let lastValue: number | null = null;

    sortedDates.forEach((date, index) => {
      sectorNames.forEach((sectorName) => {
        const sectorValue = data[fund][date]?.[sectorName];
        if (sectorValue !== undefined) {
          const currentValue = parseFloat(sectorValue.replace('%', ''));

          if (lastValue !== null && lastValue !== currentValue) {
            lastChangeDate = date;
          }

          lastValue = currentValue;
          values[index] = currentValue;
        }
      });
    });

    if (lastChangeDate) {
      const previousDateIndex = sortedDates.indexOf(lastChangeDate) - 1;
      if (previousDateIndex >= 0) {
        const previousValue = values[previousDateIndex];
        const currentValue = values[sortedDates.indexOf(lastChangeDate)];

        if (previousValue !== null && currentValue !== null) {
          alerts.push(
            `${lastChangeDate} : ${fund.trim()} is changed %AUM from ${previousValue}% to ${currentValue}%`
          );
        }
      }
    }

    // Set the value of the first date to the next available value if it's missing
    if (values[0] === null && values.slice(1).some((val) => val !== null)) {
      values[0] = values.slice(1).find((val) => val !== null)!;
    }

    // Set the value of the last date to the previous available value if it's missing
    if (
      values[values.length - 1] === null &&
      values.slice(0, -1).some((val) => val !== null)
    ) {
      values[values.length - 1] = values
        .slice(0, -1)
        .reverse()
        .find((val) => val !== null)!;
    }

    return {
      label: fund.trim(),
      data: values,
      borderColor: getRandomColor(),
      backgroundColor: "rgba(0,0,0,0)", // Transparent background
      fill: false,
    };
  });

  alerts.sort((a, b) => {
    const dateA = a.split(' ')[0]; // Extract date part from the alert message
    const dateB = b.split(' ')[0]; // Extract date part from the alert message
    return dateA.localeCompare(dateB); // Compare dates using localeCompare
  });


  return {
    chartData: {
      labels: sortedDates,
      datasets
    },
    alerts
  };
};


const MutualFundAlert: React.FC<MutualFundAlertProps> = ({
  mutualFundsAlerts,
}) => {
  const {chartData,alerts} = processData(mutualFundsAlerts);

  // console.log("Chart Data:", chartData); // Debugging

  return (
    <div className="">
      <div className="mutual-funds-graph">
        <Line
          data={chartData}
          height={600}
          width={900}
          options={{
            spanGaps: true,
            plugins: {
              legend: {
                display: true,
                position: "right",
                labels: {
                  usePointStyle: true,
                  pointStyle:"line",
                  padding: 12,
                  font: {
                    size: 12,
                  }
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Date",
                },
                ticks:{
                  minRotation:90,
                }
              },
              y: {
                title: {
                  display: true,
                  text: "% Value",
                },
                ticks: {
                  callback: function (value) {
                    return value + "%"; // Adds '%' to y-axis labels
                  },
                },
              },
            },
          }}
        />
      </div>
      {/* show alert statements for above graph */}
      <div className="mutual-fund-alerts">
        <h4 className="title">Alerts</h4>
        <ul>
          {alerts.map((alert, index) => (
            <li key={index}>{alert}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MutualFundAlert;
