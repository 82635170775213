import { Link } from "react-router-dom";

import "./HomeSection.scss";
import PriceCardHome from "./PriceCardHome";
import { Tooltip } from "react-tooltip";
import { AlertData, AlertsJson } from "../../types/alerts.json";
import { useEffect, useState } from "react";

import axios from "axios";
import StockSelectionDropdown from "./StockSelectionDropdown";

const HomeSection = () => {
  const [data, setData] = useState<AlertData | null>(null);
  const [optionsData, setOptionsData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: summaryData } = await axios.get(
          `${process.env.REACT_APP_API_URL}/summary.json`
        );

        if (summaryData?.summary) {
          const arr = summaryData.summary.map((item: any) => ({
            value: item.Stock_Ticker,
            label: item.Stock_Ticker,
          }));
          //sort by label
          arr.sort((a: any, b: any) => a.label.localeCompare(b.label));
          setOptionsData(arr);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_API_URL || "") + "/Alerts/alerts.json"
      );
      const data: AlertsJson = await response.json();

      // Find latest date
      const latestDate = Object.keys(data).sort().reverse()[0];

      setData({ ...data[latestDate], date: latestDate });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!data) {
    return (
      <>
        <div className="loading">Loading...</div>
      </>
    );
  }

  const RedDownArrow = () => (
    <svg
      style={{
        height: "1rem",
        aspectRatio: "1/1",
        marginLeft: "0.5rem",
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 18"
      fill="#ED0000"
    >
      <path
        d="M7.57637 17.2019L0.583213 0.417997L14.5695 0.417996L7.57637 17.2019Z"
        fill="#ED0000"
      />
    </svg>
  );

  const GreenUpArrow = () => (
    <svg
      style={{
        height: "1rem",
        aspectRatio: "1/1",
        marginLeft: "0.5rem",
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 18"
      fill="#2C8745"
    >
      <path
        d="M7.57598 0.908096L14.5691 17.692L0.582821 17.692L7.57598 0.908096Z"
        fill="#2C8745"
      />
    </svg>
  );

  return (
    <section className="landingv2-home-section">
      {/* Main title */}
      <h1 className="home-title">AlgoFabric: a financial insights companion</h1>
      <div className="home-subtitle">
        With the power of NLP, Algofabric crafts precision stock reports, the
        perfect financial strategy for your financial journey.
      </div>
      {/* Search Ticker bar */}
      {/* <form className="search-ticker">
        <img
          src="assets/images/landingv2/tabler-icon-search.png"
          alt="search-icon"
        />
        <select
          // type="text"
          placeholder="Search Ticker Name"
          className="search-ticker-input"
          autoFocus={false}
          onChange={(e: any) => {
            console.log(e.target.value);
            // e.preventDefault();
            //redirect to /detail?ticker=XYZ
            const ticker = e.target.value;
            if (ticker) {
              // window.location.href = `/detail?ticker=${ticker}`;
              window.localStorage.setItem("selectedTab", "0");
              window.localStorage.setItem("tickerId", ticker);
              window.location.href = `/detail`;
            }
          }}
        >
          <option value="" disabled selected>
            Search Ticker Name
          </option>
          {optionsData.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </form> */}
      <StockSelectionDropdown stocks={optionsData} />
      {/* get insights section */}
      <div className="home-insights-section">
        <div className="insights-section">
          <div className="rock-img">
            <img src="assets/images/landingv2/Rock.png" alt="Rock img" />
          </div>
          <div className="price-change-table">
            <PriceCardHome
              headers={[
                "Stock Ticker",
                <>
                  <div className="price-change-value-header">
                    Value (% Change)
                  </div>
                  <Tooltip anchorSelect=".price-change-value-header">
                    Percent of change from previous day
                  </Tooltip>
                </>,
              ]}
              values={Object.keys(
                data.price_change_alerts.top_n_price_change.stock_symbol
              )
                // Filter by ticker
                // .filter((key) d=> {
                //   if (!tickerFilter) return true;
                //   return key === tickerFilter;
                // })
                .map((key, i) => {
                  const isRed =
                    data.price_change_alerts.top_n_price_change.stock_symbol[
                      key
                    ] < 0;
                  return [
                    key,
                    <div className={isRed ? "text-red" : "text-green"} key={i}>
                      {data.price_change_alerts.top_n_price_change.stock_symbol[
                        key
                      ]
                        .toPrecision(3)
                        .toString()}
                      {isRed ? <RedDownArrow /> : <GreenUpArrow />}
                    </div>,
                  ];
                })
                .flat()}
            />
          </div>
        </div>

        <div className="insights-text-btn">
          <div className="insights-text">
            <h1>Insights within Seconds</h1>
            <p>
              Stay ahead of the curve with our dynamic market insights! Get
              real-time updates on the latest financial trends, breaking news,
              and key events that impact the markets. Whether it's major company
              moves or the hottest headlines, we've got you covered with the
              essential intel you need to make smart, timely decisions.
            </p>
          </div>
          <Link to="/trending">
            <button className="insights-button">Get Insights</button>
          </Link>
        </div>
      </div>

      {/* Button  */}
      {/* <Link to="/dashboard">
        <button className="home-button">Go to Dashboard</button>
      </Link> */}
    </section>
  );
};

export default HomeSection;
