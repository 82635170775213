import "./MarketSubscribeSection.scss";
import { useRef } from "react";
import NewsletterDialog from "./NewsletterSection/NewsletterDialog";

const MarketSubscribeSection = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  return (
    <section className="market-section">
      <div className="market-container">
        <div className="market-text-btn">
          <div className="market-title">
            <h1>Gain Your Market Edge Daily!</h1>
          </div>
          <div className="market-content">
            Stay on top of the game with real-time market updates, trend
            analysis, and tailored stock recommendations. Your ultimate tool for
            smart investing—delivered straight to your inbox, free of charge!
          </div>
          <div className="button">
            <button onClick={() => dialogRef.current?.showModal()}>
              Subscribe
            </button>
          </div>
        </div>
        <div className="market-img">
          <img
            src="assets/images/landingv2/market-img.png"
            alt="market-insights"
          />
        </div>
      </div>
      <NewsletterDialog dialogRef={dialogRef} />
    </section>
  );
};

export default MarketSubscribeSection;
