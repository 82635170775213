import React, { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import type { SectorResults, CompanyData } from "./InterfaceForJSON";
import "./StockResultsTable.css";

const apiUrl ="https://stockc2c.blob.core.windows.net/c2c-pnl-result/c2c-result.json";

const StockResultsTable: React.FC = () => {
  const [sectorResults, setSectorResults] = useState<SectorResults | null>(
    null
  );
  const [selectedSector, setSelectedSector] = useState<string | null>(null);
  const [expandedCompany, setExpandedCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const textResponse = await response.text();
        const validJsonString = textResponse.replace(/NaN/g, "null");
        const preprocessData: SectorResults = JSON.parse(validJsonString);

        setSectorResults(preprocessData);

        const sectors = Object.keys(preprocessData);
        if (sectors.length > 0) {
          setSelectedSector(sectors[0]);
          const firstCompany =
            Object.keys(preprocessData[sectors[0]]).find(
              (key) => key !== "average_accuracy" && key !== "avg_confidence"
            ) || "";
          setExpandedCompany(firstCompany);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    fetchData();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!sectorResults) {
    return <div>No data available.</div>;
  }

  const sectors = Object.keys(sectorResults);

  const handleSectorToggle = (sector: string) => {
    setSelectedSector(sector);
    const firstCompany = Object.keys(sectorResults[sector]).find(
      (key) => key !== "average_accuracy" && key !== "avg_confidence"
    );
    if (firstCompany) {
      setExpandedCompany(firstCompany);
    }
  };

  const handleCompanyToggle = (company: string) => {
    setExpandedCompany(company);
  };

  const isCompanyData = (data: number | CompanyData): data is CompanyData => {
    return (data as CompanyData).accuracy !== undefined;
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const ConfusionMatrixTable: React.FC<{ data: CompanyData }> = ({ data }) => {
    return (
      <div className="confusion-matrix-wrapper">
        {/* <h5>Confusion Matrix</h5> */}
        <table className="confusion-matrix">
          <thead>
            <tr>
              <th></th>
              <th>Predicted Positive</th>
              <th>Predicted Negative</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Actual Positive</th>
              <td className="positive green">
                <span style={{ fontSize: "smaller" }}>
                  Trading Days:{" "}
                  {data["Confusion Matrix"]["True Positive"] ??
                    "Data not available"}
                </span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  % Profit:{" "}
                  {data["Percentage Profit from TP"].toFixed(2) ??
                    "Data not available"}
                </span>
              </td>
              <td className="negative red">
                <span style={{ fontSize: "smaller" }}>
                  Trading Days:{" "}
                  {data["Confusion Matrix"]["False Negative"] ??
                    "Data not available"}
                </span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  % Loss:{" "}
                  {data["Percentage Loss from FN"].toFixed(2) ??
                    "Data not available"}
                </span>
              </td>
            </tr>
            <tr>
              <th>Actual Negative</th>
              <td className="negative red">
                <span style={{ fontSize: "smaller" }}>
                  Trading Days:{" "}
                  {data["Confusion Matrix"]["False Positive"] ??
                    "Data not available"}
                </span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  % Loss:{" "}
                  {data["Percentage Loss from FP"].toFixed(2) ??
                    "Data not available"}
                </span>
              </td>
              <td className="positive green">
                <span style={{ fontSize: "smaller" }}>
                  Trading Days:{" "}
                  {data["Confusion Matrix"]["True Negative"] ??
                    "Data not available"}
                </span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  % Profit:{" "}
                  {data["Percentage Profit from TN"].toFixed(2) ??
                    "Data not available"}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="sector-list">
        <table className="sector-table">
          <thead>
            <tr>
              <th>Sectors</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sectors.map((sector) => (
              <React.Fragment key={sector}>
                <tr
                  className="sector-header"
                  onClick={() => handleSectorToggle(sector)}
                >
                  <td className="sector-name">{sector}</td>
                  <td>
                    {selectedSector === sector ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </td>
                </tr>
                {selectedSector === sector && (
                  <tr>
                    <td colSpan={2}>
                      {" "}
                      {/* Adjusted colspan since avg accuracy column is removed */}
                      <div className="company-list">
                        <table className="company-table">
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Accuracy</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(sectorResults[sector])
                              .filter(
                                (key) =>
                                  key !== "average_accuracy" &&
                                  key !== "avg_confidence"
                              )
                              .map((company) => (
                                <React.Fragment key={company}>
                                  <tr
                                    className="company-row"
                                    onClick={() => handleCompanyToggle(company)}
                                  >
                                    <td>{company}</td>
                                    <td>
                                      {isCompanyData(
                                        sectorResults[sector][company]
                                      ) && (
                                        <span className="company-accuracy">
                                          {(
                                            sectorResults[sector][
                                              company
                                            ] as CompanyData
                                          ).accuracy.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {isMobile &&
                                        (expandedCompany === company ? (
                                          <IoIosArrowUp />
                                        ) : (
                                          <IoIosArrowDown />
                                        ))}

                                      {!isMobile && (
                                        <button
                                          className="show-matrix"
                                          onClick={handleModalOpen}
                                        >
                                          Show Confusion Matrix
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                  {expandedCompany === company && isMobile && (
                                    <tr>
                                      <td colSpan={3}>
                                        <button
                                          className="show-matrix"
                                          onClick={handleModalOpen}
                                        >
                                          Show Confusion Matrix
                                        </button>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* {!isMobile && expandedCompany && selectedSector && (
        <div className="company-details">
          <h3 className="company-name-header">{expandedCompany} Details</h3>
          <ConfusionMatrixTable
            data={sectorResults[selectedSector][expandedCompany] as CompanyData}
          />
        </div>
      )} */}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleModalClose}>
              &times;
            </span>
            <h3 className="company-name-header"><span style = {{fontWeight :"bold"}}>{expandedCompany} </span>Confusion Matrix</h3>
            <ConfusionMatrixTable
              data={
                sectorResults[selectedSector || ""][
                  expandedCompany || ""
                ] as CompanyData
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StockResultsTable;
