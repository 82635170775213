import { ReactElement } from "react";
import "./PriceCard.scss";

type PriceCardProps = {
  title: string | ReactElement;
  headers: Array<string | ReactElement>;
  values: Array<string | ReactElement>;
  toggleInfoBox?: () => void;
  highlightBox?: boolean;
  setInfoBoxRef?: (ref: HTMLDivElement | null) => void;
};

const PriceCard = ({ toggleInfoBox, ...props }: PriceCardProps) => {
  return (
    <div
      className="trending-price-change-card"
      style={props.highlightBox ? { zIndex: 20 } : {}}
      ref={ref => props.setInfoBoxRef && props.setInfoBoxRef(ref)}
    >
      <h3 className="title">{props.title}</h3>
      {/* Ticker value table */}
      <div
        className="price-grid"
        style={{
          gridTemplateColumns: `repeat(${props.headers.length}, auto)`,
        }}
      >
        {/* Headers */}
        {props.headers.map((header, i) => (
          <div className="header" key={i}>
            {header}
          </div>
        ))}
        {/* Values */}
        {props.values.map((value, i) => (
          <div className="value" key={i}>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PriceCard;
