import { useState,useEffect } from 'react';
import axios from 'axios';
import MutualFundTable from './MutualFundTable';
import MutualFundAlert from './MutualFundAlert';

import "./MutualFunds.css"

const MutualFunds = () => {

    const [mutualFundsData, setMutualFundsData] = useState<any>({});
    const [mutualFundsAlertsData, setMutualFundsAlertsData] = useState<any>({});

    const tickerId = window.localStorage.getItem("tickerId");

    const mutualFunds = async () => {
        try {
          const { data: mutualFunds } = await axios.get(
            `${process.env.REACT_APP_COMPANY_DOC}/${tickerId}/mutual_funds.json`
          );
          setMutualFundsData(mutualFunds);
          // console.log(mutualFunds);
        } catch (ex) {
        } finally {
        }
      };
    
      const mutualFundsAlerts = async () => {
        try {
          const { data: mutualFundsAlerts } = await axios.get(
            `${process.env.REACT_APP_COMPANY_DOC}/${tickerId}/alerts.json`
          );
          setMutualFundsAlertsData(mutualFundsAlerts);
          // console.log(mutualFundsAlerts);
        } catch (ex) {
        } finally {
        }
      };
      
        useEffect(() => {
            mutualFunds();
            mutualFundsAlerts();
        }, []);

    return (
        <div className='mutual-funds-split'>
            <MutualFundTable mutualFunds={mutualFundsData}  />
            <MutualFundAlert mutualFundsAlerts={mutualFundsAlertsData} />
        </div>
    );
};

export default MutualFunds;