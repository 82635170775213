import moment from "moment";


//hide probablity score from 3 to 3:30
/**
 * This function determines whether to hide the probability score at a specific time.
 * It hides the score from 3:00 PM to 3:30 PM on weekdays (Monday to Friday).
 *
 * @returns {boolean} - True to show the item, false to hide it.
 */
export const hideProbablityAtDateTime = () => {

    const day = moment().day(); // Number;  //Day of Week (Sunday as 0, Saturday as 6)

    const customFormat = 'YYYY-MM-DD HH:mm:ss';

    const time = moment(moment(), customFormat); //date  ---> 2023-08-18 15:32:00  

    const beforeTime = moment(moment(), customFormat).hours(15).minutes(0).seconds(0);  //minutes and seconds from 0 to 59, hours --> from 0 to 23
    const afterTime = moment(moment(), customFormat).hours(15).minutes(30).seconds(0);

    const condition = time.isBetween(beforeTime, afterTime, undefined, '[]');

    let showItem = true;

    if (day === 0 || day === 6 || condition === false) {
        showItem = false; //hide item if its sat, sunday or not between 3 PM to 3:30 PM
    }

    //console.log('dayyyyy', day, time, beforeTime, condition);

    return showItem
};


//done for faster iteration
/**
 * This function converts an array of data into an object with ticker symbols as keys for faster iteration.
 *
 * @param {Array} dataArr - The array of data to be converted.
 * @returns {Object} - An object with ticker symbols as keys.
 */
export const getCorrelationState = (dataArr) => {
    const newDataObject = {}

    if (dataArr.length === 0) {
        //ignore
    } else {
        dataArr.forEach(item => {
            // console.log('getCorrelationState', item[`Stock Symbol`]);
            const currTicker = item[`Stock Symbol`];
            newDataObject[currTicker] = item
        });
    }

    //console.log('getCorrelationState', newDataObject);
    return newDataObject;
};


// ------------------START  getSentimentCorrelationValue --------------------------------------

//stockprice_sentiment_correlation
// Probability of stock price going up on next day when sentiment score > Mean+Std (No. of records) (Signal 1)
// 	Probability of stock price going down on next day when sentiment score < Mean-Std (No. of records) (Signal -1)
//     	Probability of stock price going up on next day when sentiment score is within one std. dev. of mean (No. of records) (Signal 0.5)
//          	Probability of stock price going down on next day when sentiment score is within one std. dev. of mean (No. of records) (Signal -0.5)


//light --> 0. 
//dark  ---> 1

//blue --> Up
//brown --> down
/**
 * This function calculates the sentiment correlation value for a specific ticker symbol and data object.
 * It returns an object with the probability score and its corresponding color.
 *
 * @param {string} ticker - The ticker symbol of the company.
 * @param {Object} dataObj - The data object containing sentiment correlation information.
 * @returns {Object} - An object with probability score and color.
 */
export const getSentimentCorrelationValue = (ticker, dataObj) => {

    // const ProbOne = `Probability of stock price going up on next day when sentiment score > Mean+Std (No. of records)`;
    // const ProbMinusOne = `Probability of stock price going down on next day when sentiment score < Mean-Std (No. of records)`;
    // const ProbZeroPointFive = `Probability of stock price going up on next day when sentiment score is within one std. dev. of mean (No. of records)`;
    // const ProbMinusZeroPointFive = `Probability of stock price going down on next day when sentiment score is within one std. dev. of mean (No. of records)`;


    //renamed to below in json
    const ProbOne = `Probability of stock price going up when sentiment score > Mean+Std (No. of records)`;
    const ProbMinusOne = `Probability of stock price going down when sentiment score < Mean-Std (No. of records)`;
    const ProbZeroPointFive = `Probability of stock price going up when sentiment score is within one std. dev. of mean (No. of records)`;
    const ProbMinusZeroPointFive = `Probability of stock price going down when sentiment score is within one std. dev. of mean (No. of records)`;

    const darkBlue = '#0cbaf2';  // ProbOne
    const darkBrown = '#e8a96b'; //ProbMinusOne

    const lightBlue = '#ADD8E6';   //ProbZeroPointFive
    const lightBrown = '#d1bca7'; //ProbMinusZeroPointFive

    const obj = { probailityScoreColor: "rgb(204, 204, 204)", probailityScoreValue: 0 }; //default 

    if (!ticker) {
        //ignore
    } else if (!dataObj || Object.keys(dataObj || {})?.length === 0) {
        //ignore
    } else if (Object.keys(dataObj[ticker] || {})?.length === 0) {
        //ignore
    }else {
        const currentTickerObj = dataObj[ticker];  //currentCompany ticker data
        //console.log('currentTickerObj', ticker, dataObj[ticker]);

        if (currentTickerObj.Signal === 1) {

            obj.probailityScoreValue = currentTickerObj[ProbOne];
            obj.probailityScoreColor = darkBlue;

        } else if (currentTickerObj.Signal === -1) {

            obj.probailityScoreValue = currentTickerObj[ProbMinusOne];;
            obj.probailityScoreColor = darkBrown;

        } else if (currentTickerObj.Signal === 0.5) {

            obj.probailityScoreValue = currentTickerObj[ProbZeroPointFive];;
            obj.probailityScoreColor = lightBlue;

        } else if (currentTickerObj.Signal === -0.5) {

            obj.probailityScoreValue = currentTickerObj[ProbMinusZeroPointFive];;
            obj.probailityScoreColor = lightBrown;
        }
        //if signal is equal to 0 ---> default condition runs
        // else  if(currentTickerObj.Signal === 0){
        // }

    }

    return obj; //returns array 

};

// ------------------END !!!!  getSentimentCorrelationValue --------------------------------------



///table data array creation 

//check if doesnt exist like getSentimentCorrelationValue
/**
 * This function combines summary data and probability data to create modified table data.
 *
 * @param {Array} summaryData - The summary data array.
 * @param {Object} probData - The probability data object.
 * @returns {Array} - Modified table data.
 */
export const getDashTableCombinedData = (summaryData, probData) => {

    const modifiedData = [];

    if (summaryData?.length === 0) {
        //ignore
    } else {
        for (let step = 0; step < summaryData.length; step++) {

            const ticker = summaryData[step].Stock_Ticker;

            //if no ticker or probData present getSentimentCorrelationValue returns ---> const obj = { probailityScoreColor: "rgb(204, 204, 204)", probailityScoreValue: 0 };
            const dataObject = getSentimentCorrelationValue(ticker, probData); 
            modifiedData.push({ ...summaryData[step], ...dataObject });

        }
    }

    // console.log('final', modifiedData);
    return modifiedData;
};


/**
 * This function sorts rows in a dashboard table based on a specified property and sorting filter.
 *
 * @param {Array} data - The array of objects to be sorted.
 * @param {string} property - The property name used for sorting.
 * @param {string} sortFilter - The sorting criteria ('asc' or 'desc').
 * @returns {Array} - The sorted array of objects.
 */
export const sortDashboardTableRows = (data, property, sortFilter) => {

    if (data?.length > 0 && property && sortFilter) {    //sortFilter is asc or desc, 
        const order = sortFilter === 'desc' ? -1 : 1;
        // console.log(property, sortFilter, order);
        return ([...data].sort((a, b) => (a[property] > b[property]) ? order : ((b[property] > a[property]) ? -order : 0)));
        //sort array in same `property` order;
    } else {
        return [];
    }

};


