import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import Navbar from "../Component/LandingV2/Navbar";
import MiniFooter from "../Component/LandingV2/MiniFooter";
import StockGridCard from "../Component/StockSelection/StockGridCard";
import SunburstChart from "../Component/StockSelection/SunburstChart";
import HistoricalData from "../Component/StockSelection/HistoricalData";

import "./StockSelectionPage.scss";

import StockCardDataDummy from "../data/stock-selection/2023-12-18.json";
import StockLogDummy from "../data/stock-selection/log-stocks-data.json";
import StockTableDataDummy from "../data/stock-selection/2024-05-18.json";
import SubNavbar from "../Component/LandingV2/SubNavbar";
import ShowcaseTable from "../Component/StockSelection/ShowcaseTable";

export type StockSelectionCardData = {
  Stock: string;
  Sector: string;
  Industry: string;
  "5 Day Avg Volume (Cr)": number;
  "1_Year_Return": number;
  "1_day_return": number;
  prev_close: number;
  "MKT CAP": number;
  "Company Name": string;
  "1_month_return": number;
};

type LogStocksDataType = {
  [key: string]: {
    [key: string]: number;
  };
};

export type StockSelectionTableData = {
  Stock: string;
  Sector: string;
  Industry: string;
  "5 Day Avg Volume (Cr)": number;
  "1_Year_Return": number;
  Difference_wrt_Nifty: number;
  "Index Satisfied": string;
};

type portfolioTypeEnum = "β-Based Stock Membership Strategy" | "NSE Stock Membership Strategy";

const StockSelectionPage = () => {
  const [cardData, setCardData] = useState<StockSelectionCardData[] | null>(
    StockCardDataDummy
  );
  const [logStocksData, setLogStocksData] = useState<LogStocksDataType | null>(
    StockLogDummy
  );
  const [tableData, setTableData] = useState<StockSelectionTableData[] | null>(
    StockTableDataDummy
  );

  const [selectedFilter, setSelectedFilter] = useState<string>("2");
  const [portfolioType, setPortfolioType] = useState<portfolioTypeEnum>("β-Based Stock Membership Strategy");

  const subNavData = [
    {
      name:"Strong Stocks",
      href:"/strong-stocks-home",
      dropdown:[{
          name: "Home",
          href: "/strong-stocks-home",
        },
        {
          name: "Stock Selection",
          href: "/stock-selection",
        }, 
      ]
    },
    {
      name:"Nifty Statergy",
      href:"/nifty-statergy",
    },
    {
      name:"Stock C2C",
      href:"/stock-c2c",
    }
  ]
  
  const fetchCardData = async () => {
    try {
      // Date in YYYY-MM-DD format
      const today = new Date().toISOString().split("T")[0];
      const res = await fetch(
        (process.env.REACT_APP_API_URL || "") +
          `/Stock-Selection/web-files-v2/${today}.json`
      );
      const data: StockSelectionCardData[] = await res.json();
      setCardData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTableData = async () => {
    try {
      // Date in YYYY-MM-DD format
      // FIXME: dummy date
      const today = new Date().toISOString().split("T")[0];
      // const today = new Date().toISOString().split("T")[0];
      console.log("Fetch table data ", portfolioType);
      const res = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/fabric-universe-files/${encodeURI(
            portfolioType || ""
          )}/universe1-2-files/${today}.json`
      );
      const data: StockSelectionTableData[] = await res.json();
      setTableData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLogStocksData = async () => {
    try {
      const res = await fetch(
        (process.env.REACT_APP_API_URL || "") +
          "/Stock-Selection/log-files-v2/log-stocks-data.json"
      );
      const data: LogStocksDataType = await res.json();
      setLogStocksData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCardData();
    fetchLogStocksData();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [portfolioType]);

  if (!cardData || !logStocksData) {
    return <div>Loading...</div>;
  }

  // console.log(logStocksData);
  // console.log(tableData);

  return (
    <>
      <Navbar />
      <SubNavbar navData={subNavData} />
      <div className="stock-selection-options">
        <div className="secondary-navbar">
          <button
            className={`secondary-navbar-tab ${portfolioType === "β-Based Stock Membership Strategy"? "active" : ""}`}
            onClick={() => {
              console.log("β-Based Stock Membership Strategy");
              setPortfolioType("β-Based Stock Membership Strategy");
            }}
          >
            β-Based Stock Membership Strategy
          </button>
          {/* <button
            className={`secondary-navbar-tab ${portfolioType === "NSE Stock Membership Strategy"? "active" : ""}`}
            onClick={() => {
              console.log("NSE Stock Membership Strategy");
              setPortfolioType("NSE Stock Membership Strategy");
            }}
          >
            NSE Stock Membership Strategy
          </button> */}
        </div>
        <hr className="divider" />
      </div>
      {/* Filter selectbox */}
      <div className="filter-selectbox">
        {/* <label htmlFor="filter">Filter By</label> */}
        
        <select
        
          name="filter"
          id="filter"
          onChange={(e) => setSelectedFilter(e.target.value)}
        >
          <option value="" >Filter By</option>
          <option value="1">Steady Performers & Nifty Beaters</option>
          <option value="2">Optimized Performers</option>
        </select>
      </div>
      <section className="stock-selection-page">
        {(selectedFilter === "" || selectedFilter === "2") && (
          <>
            {/* Todays pick card */}
            <div className="today-pick-card">
              <h2 className="title">Today's Picks</h2>
              {/* Card grid  */}
              <div className="stock-grid">
                {cardData.map((item, i) => (
                  <StockGridCard
                    key={i}
                    ticker={item.Stock}
                    companyName={item["Company Name"]}
                    sector={item.Sector}
                    industry={item.Industry}
                    marketCap={`${(item["MKT CAP"] / 10000000).toFixed(2)}`}
                    volume={item["5 Day Avg Volume (Cr)"].toFixed(2)}
                    fiftyTwoWeekReturn={item["1_Year_Return"]}
                    oneMonthReturn={item["1_month_return"]}
                    ltp={item.prev_close}
                  />
                ))}
              </div>
            </div>
            {/* two-pane-split */}
            <div className="two-pane-split">
              {/* left-pane */}
              <div className="pane">
                <h2 className="title">Sector-wise Division of Stocks</h2>
                {/* Sunburst chart  */}
                <SunburstChart cardData={cardData} />
              </div>
              {/* right-pane */}
              <div className="pane">
                <h2 className="title">Normalized Stock Prices</h2>
                {/* Normalized Graph */}
                <Plot
                  data={Object.keys(logStocksData).map((item) => ({
                    x: Object.keys(logStocksData[item]),
                    y: Object.values(logStocksData[item]),
                    type: "scatter",
                    mode: "lines",
                    name: item,
                    // line: {
                    //   color: "rgb(219, 64, 82)",
                    //   width: 3,
                    // },
                  }))}
                  layout={{
                    autosize: true,
                    legend: { orientation: "h" },
                    margin: { l: 40, r: 40, b: 80, t: 40 },
                  }}
                  useResizeHandler
                  style={{ width: "100%", aspectRatio: "4/3" }}
                  config={{
                    displayModeBar: false,
                  }}
                />
              </div>
            </div>
          </>
        )}
        {(selectedFilter === "" || selectedFilter === "1") && (
          <ShowcaseTable tableData={tableData} />
        )}
      {/* {(selectedFilter === "" || selectedFilter === "2") && (
        <HistoricalData />
      )} */}
      </section>
      <MiniFooter />
    </>
  );
};

export default StockSelectionPage;
