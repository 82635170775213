import Plot from "react-plotly.js";

import { StockSelectionCardData } from "../../Pages/StockSelectionPage";

import "./SunburstChart.scss";

type SunburstChartProps = {
  cardData: StockSelectionCardData[];
};

const SunburstChart = ({ cardData }: SunburstChartProps) => {
  // Sunburst chart data
  let sunburstLabels: string[] = [];
  let sunburstParent: string[] = [];

  const replaceSpaceWithNewLine = (str: string) => {
    return str.replace(/\s/g, "<br>");
  };

  cardData.forEach((stock) => {
    if (!sunburstLabels.includes(replaceSpaceWithNewLine(stock.Sector))) {
      sunburstLabels.push(replaceSpaceWithNewLine(stock.Sector));
      sunburstParent.push("");
    }
    if (!sunburstLabels.includes(replaceSpaceWithNewLine(stock.Industry))) {
      sunburstLabels.push(replaceSpaceWithNewLine(stock.Industry));
      sunburstParent.push(replaceSpaceWithNewLine(stock.Sector));
    }
    sunburstLabels.push(replaceSpaceWithNewLine(stock.Stock));
    sunburstParent.push(replaceSpaceWithNewLine(stock.Industry));
  });

  return (
    <div className="sunburst-chart">
      <Plot
        data={[
          {
            type: "sunburst",
            labels: sunburstLabels,
            parents: sunburstParent,
            // outsidetextfont: {size: 20, color: "#377eb8"},
            // leaf: {opacity: 0.4},
            marker: { line: { width: 2 } },
          },
        ]}
        layout={{
          margin: { l: 0, r: 0, b: 0, t: 0 },
          autosize: true,
        }}
        useResizeHandler
        style={{ width: "100%", aspectRatio: "4/3" }}
        config={{
          displayModeBar: false,
        }}
      />
    </div>
  );
};

export default SunburstChart;
