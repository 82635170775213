import React, { useState, useEffect } from "react";
import "./newOverview.scss";
import { overviewProps } from "./overview.types";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import CircularProgress from "@mui/material/CircularProgress";

interface OverviewData {
  Query_1?: string;
  Query_2?: string;
  Query_3?: string;
  Query_4?: string;
}

const NewOverview = (props: overviewProps) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [data, setData] = useState<OverviewData[]>([]);
  const { stock_name } = props;
  const [inputValue, setInputValue] = useState("");
  const [apiResponse, setApiResponse] = useState<string>(""); // State to store API response
  const ticker = localStorage.getItem("tickerId");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMPANY_DOC}/RAG-Query-Results/${ticker}/response.json`
      );
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const formatQuery1 = (text: string | undefined) => {
    return text ? text.replace("Summary:", "") : "No summary available";
  };

  const formatQuery2 = (text: string | undefined) => {
    if (!text) return "No sentiment analysis available";

  const lines = text.split(/[\r\n]+/).filter(line => line.trim() !== '');
  const formattedLines = lines
    .slice(1)
    .map((line, index) => 
    index === 0 ? line : `• ${line.trim()}`
  ).join('\n');

  return formattedLines || "No sentiment analysis available";
  };

  const formatQuery3 = (text: string | undefined) => {
    return text
      ? text
          .replace("Key events for the company are as follows:\n\n", "")
          .replace(/[•*]/g, "")
      : "No key events available";
  };

  const formatQuery4 = (text: string | undefined) => {
    return text
      ? text.replace("Share market events: ", "")
      : "No share market events available";
  };

  const getSentimentColor = (query2: string | undefined) => {
    if (query2?.includes("Bullish")) {
      return  "#E8F5E9"; // Green
    } else if (query2?.includes("Bearish")) {
      return "#FFEBEE"; // Red
    } else if (query2?.includes("Neutral")) {
      return "#FFF5B2"; // Yellow
    } else {
      return "#FFFFFF"; // Default White if no sentiment is found
    }
  };

  // // Determine if sentiment is bullish
  // const isBullish = data[1]?.Query_2?.includes("Bullish");
  // console.log(stock_name);

  // Handler for chatbot button clicks
  const handleButtonClick = (text: string) => {
    setInputValue(text);
  };

  // Fetch JSON data from API
  const fetchJsonData = async () => {
    const url = `${process.env.REACT_APP_OVERVIEW_CHATBOT_URL}`;
    try {
      setIsLoaderVisible(true);
      const response_from_api = await axios.post(
        url,
        {
          prompt: inputValue,
          ticker_name: stock_name,
        },
        {
          // headers: {
          //   "Content-Type": "application/json",
          // },
        }
      );

      // Set the API response to the state
      setApiResponse(response_from_api.data.response);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setIsLoaderVisible(false);
    }
  };

  // Handler for key press
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior
      fetchJsonData();
    }
  };

  // Handler for search button click
  const handleSearchClick = () => {
    fetchJsonData();
  };

  return (
    <div className="overview-container">
      <div className="left-content-container">
        {data.length > 0 && (
          <>
            <div className="grid-item">
              <h2>SUMMARY</h2>
              <div className="content">
                <ul>
                  <li>{formatQuery1(data[0]?.Query_1)}</li>
                </ul>
              </div>
            </div>

            <div className="grid-item">
              <h2>KEY EVENTS</h2>
              <div className="content">
                <ul>
                  {(formatQuery3(data[2]?.Query_3)?.split("\n") || []).map(
                    (event, index) => <li key={index}>{event}</li>
                  ) || <li>No key events available</li>}
                </ul>
              </div>
            </div>

            <div className="grid-item">
              <h2>SHARE MARKET EVENTS</h2>
              <div className="content">
                <ul>
                  {(formatQuery4(data[3]?.Query_4)?.split("\n") || []).map(
                    (event, index) => (
                      <li key={index}>{event}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="right-content-container">
        <div className="grid-item">
          <div className="chatbot-container">
            <div className="chatbot-header">CHATBOT</div>
            <div className="chatbot-body">
              {/* Conditionally render the chatbot buttons */}
              {!apiResponse && (
                <>
                  <div
                    className="chatbot-button"
                    onClick={() =>
                      handleButtonClick(
                        "How is the Company's financial performance?"
                      )
                    }
                  >
                    How is the Company's financial performance?
                  </div>
                  <div
                    className="chatbot-button"
                    onClick={() =>
                      handleButtonClick(
                        "What recent news has the company released for its investors?"
                      )
                    }
                  >
                    What recent news has the company released for its investors?
                  </div>
                  <div
                    className="chatbot-button"
                    onClick={() =>
                      handleButtonClick(
                        "What are the latest news headlines for the Company?"
                      )
                    }
                  >
                    What are the latest news headlines for the Company?
                  </div>
                </>
              )}
              {isLoaderVisible && (
                <div className="loader">
                  {" "}
                  <CircularProgress />
                </div>
              )}

              <div className="response-box">
                {apiResponse && <ReactMarkdown>{apiResponse}</ReactMarkdown>}
              </div>
            </div>

            <div className="chatbot-footer">
              <div className="input-container">
                <textarea
                  className="chatbot-textarea"
                  placeholder="ask a query"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button className="chatbot-submit" onClick={handleSearchClick}>
                  <IoSearch />
                </button>
              </div>
            </div>
          </div>

          <div
            className="grid-item-sentiment"
            style={{ backgroundColor: getSentimentColor(data[1]?.Query_2) }}
          >
            <h2>
              SENTIMENT:
              <span
                className="sentiment-title"
                style={{
                  color: data[1]?.Query_2?.includes("Bearish")
                    ? "#FF0000"
                    : data[1]?.Query_2?.includes("Bullish")
                    ? "#008000"
                    : data[1]?.Query_2?.includes("Neutral")
                    ? "#FFA500"
                    : "#000000",
                }}
              >
                {data[1]?.Query_2?.includes("Bearish")
                  ? "Bearish"
                  : data[1]?.Query_2?.includes("Bullish")
                  ? "Bullish"
                  : data[1]?.Query_2?.includes("Neutral")
                  ? "Neutral"
                  : "No sentiment "}
              </span>
            </h2>
            <div className="content">
              <ul>
                {(formatQuery2(data[1]?.Query_2)?.split("\n") || []).map(
                  (statement, index) => (
                    <li key={index}>{statement}</li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOverview;
