import { ReactNode } from "react";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./ProtectedHOC.scss";

import { UserInfo } from "../../context/AuthContext";

type ProtectedPageProps = {
  userInfo: UserInfo | null;
  children?: ReactNode;
};

const ProtectedPageHOC = ({ children, userInfo }: ProtectedPageProps) => {
  const [loadingState, setLoadingState] = useState(false);

  const navigate = useNavigate(); // Get access to the history instance

  useEffect(() => {
    if (!userInfo) {
      // Set a timeout to redirect to the login page after 5 seconds (5000 milliseconds)
      const timer = setTimeout(() => {
        navigate("/login"); // Navigate to the login page
      }, 5000);
      setLoadingState(true);

      // Cleanup function to clear the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [userInfo, navigate]); // Depend on userInfo and history

  if (userInfo) {
    return (
      <>
        {children && children}
        <Outlet />
      </>
    );
  }
  return (
    <div className= "main-container">
      <div className="sub-container">
      <p>Redirecting to login...</p>
        <div className="animate-spin">
          {loadingState && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={40} width={40}>
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
            </svg>
          )}
        </div>
        <h2>
          This page is required you to be logged in. <br />
        </h2>
        <Link to="/login">
          <button className="button">Go to login page.</button>
        </Link>
      </div>
    </div>
  );
};
export default ProtectedPageHOC;
