import { ReactElement } from "react";
import "./PriceCardHome.scss";

type PriceCardProps = {
//   title: string | ReactElement;
  headers: Array<string | ReactElement>;
  values: Array<string | ReactElement>;
};

const PriceCard = ({...props }: PriceCardProps) => {
  return (
    <div
      className="home-price-change-card"
    >
      {/* <h3 className="title">{props.title}</h3> */}
      {/* Ticker value table */}
      <div
        className="price-grid"
        style={{
          gridTemplateColumns: `repeat(${props.headers.length}, auto)`,
        }}
      >
        {/* Headers */}
        {props.headers.map((header, i) => (
          <div className="header" key={i}>
            {header}
          </div>
        ))}
        {/* Values */}
        {props.values.map((value, i) => (
          <div className="value" key={i}>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PriceCard;
