import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './DashBoardCards.css';
import NavigateMenu from '../../../Actions/NavigateMenu';

/**
 * The `DashBoardTable` component displays a table of data on the dashboard page.
 * It includes columns for stock information such as stock ticker, trading amount, returns, news volume, and more.
 *
 * This function is called in src\Component\Dashboard\Dashboard.jsx
 *
 * @param {boolean} displayProbScore - Indicates whether to display the probability score column.
 * @param {Array} tableData - The data to be displayed in the table.
 * @returns {JSX.Element} The rendered `DashBoardTable` component.
 */

const DashBoardTable = ({ displayProbScore, tableData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const isSameKey = prevConfig.key === key;
      const direction = isSameKey && prevConfig.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  };

  const sortedData = () => {
    const { key, direction } = sortConfig;
    if (!key) return tableData;

    return [...tableData].sort((a, b) => {
      const getValue = (item) => {
        if (key === 'Trading_Amount') {
          return (item.Today_Volume * item.yestarday_close) / 10000000;
        } else if (key === 'Today_News_Sentiment') {
          return item.Today_News_Sentiment === 'NA' ? -Infinity : item.Today_News_Sentiment;
        } else {
          return item[key];
        }
      };

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleTickerChange = (ticker) => {
    window.localStorage.setItem('tickerId', ticker);
  };

  const renderTable = (columns) => (
    <Table responsive>
      <thead>
        <tr className='table-header-height'>
          {columns.map(({ key, label, onClick }) => (
            <th
              key={key}
              style={{ cursor: 'pointer' }}
              onClick={onClick ? () => handleSort(key) : undefined}
            >
              {label} <i className="fas fa-sort"></i>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData().map((summary, index) => (
          <tr key={index}>
            <td
              className="td_hover"
              onClick={() => {
                handleTickerChange(summary.Stock_Ticker);
                navigate('/detail');
                dispatch(NavigateMenu(0));
              }}
              style={{ cursor: 'pointer' }}
            >
              {summary.Stock_Ticker}
            </td>
            <td>
              <button
                className="btn"
                style={{
                  backgroundColor: summary[columns[1].colorKey],
                }}
                onClick={() => {
                  handleTickerChange(summary.Stock_Ticker);
                  navigate('/detail');
                  dispatch(NavigateMenu(columns[1].navigateMenuIndex));
                }}
              >
                {columns[1].content(summary)}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div className="main-div">
      <div className="inside-div">
        <div className="table-card">
          {renderTable([
            { key: 'Stock_Ticker', label: 'Stock Ticker', onClick: true },
            { key: 'Trading_Amount', label: 'Trading Amount (Crs)', onClick: true, colorKey: 'Today_Volume_colors', navigateMenuIndex: 1, content: (summary) => ((summary.Today_Volume * summary.yestarday_close) / 10000000).toLocaleString('en-IN') }
          ])}
        </div>
        <div className="table-card">
          {renderTable([
            { key: 'Stock_Ticker', label: 'Stock Ticker', onClick: true },
            { key: 'Today_Returns', label: 'Returns', onClick: true, colorKey: 'Today_Returns_colors', navigateMenuIndex: 1, content: (summary) => `${summary.Today_Returns} %` }
          ])}
        </div>
        <div className="table-card">
          {renderTable([
            { key: 'Stock_Ticker', label: 'Stock Ticker', onClick: true },
            { key: 'Today_News_Volume', label: 'News Volume', onClick: true, colorKey: 'Today_News_Volume_colors', navigateMenuIndex: 2, content: (summary) => summary.Today_News_Volume }
          ])}
        </div>
        <div className="table-card">
          {renderTable([
            { key: 'Stock_Ticker', label: 'Stock Ticker', onClick: true },
            { key: 'Today_News_Sentiment', label: 'News Sentiments', onClick: true, colorKey: 'Today_News_Sentiment_colors', navigateMenuIndex: 2, content: (summary) => summary.Today_News_Sentiment.toString() === 'NA' ? 'No Data' : summary.Today_News_Sentiment }
          ])}
        </div>
      </div>
    </div>
  );
};

export default DashBoardTable;
