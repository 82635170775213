import React from "react";
import Plot from "react-plotly.js";
import { GraphData, FilterData } from "./Home";

interface BacktestingGraphProps {
  graphData: GraphData | null;
  universeValue: string;
}

const BacktestingGraph = ({
  graphData,
  universeValue,
}: BacktestingGraphProps) => {
  const getFilteredData = (): any => {
    if (!graphData) return null;

    let filterData: FilterData | undefined;
    switch (universeValue) {
      case "Steady Performers":
        filterData = graphData["Filter 1 Stocks"];
        break;
      case "Nifty Beaters":
        filterData = graphData["Filter 2 Stocks"];
        break;
      case "Optimized Performers":
        filterData = graphData["Filter 3 Stocks"];
        break;
      default:
        filterData = graphData["Filter 1 Stocks"];
        break;
    }

    const dates = filterData?.["Backtesting Performance Our Stock"].Date || [];
    const ourStockInvestment = filterData?.["Backtesting Performance Our Stock"].Investment || [];
    const niftybeesInvestment = filterData?.["Backtesting Performance NIFTYBEES"].Investment || [];

    const uniqueMonths = Array.from(new Set(dates.map(date => {
      const d = new Date(date);
      return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}`;
    })));

    return {
      dates,
      datasets: [
        {
          x: dates,
          y: ourStockInvestment,
          type: "scatter",
          mode: "lines",
          name: universeValue,
          line: {
            color: "rgba(14,112,204,1)",
          },
        },
        {
          x: dates,
          y: niftybeesInvestment,
          type: "scatter",
          mode: "lines",
          name: "NIFTYBEES",
          line: {
            color: "rgba(148,208,255,1)",
          },
        },
      ],
      uniqueMonths,
    };
  };

  const plotData = getFilteredData();

  return (
    <>
      {plotData ? (
        <Plot
          data={plotData.datasets}
          layout={{
            title: "Forward Testing Performance",
            xaxis: {
              title: "Month",
              tickformat: "%b %Y",
              tickvals: plotData.uniqueMonths,
              ticktext: plotData.uniqueMonths.map((label: string) => {
                const [year, month] = label.split('-');
                const date = new Date(parseInt(year), parseInt(month) - 1);
                const monthNames = [
                  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];
                return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
              }),
            },
            yaxis: {
              title: "Value in Rs.",
            },
            legend: {
              orientation: "h",
              y: -0.2, // Position the legend further below the x-axis
            },
            margin: { l: 80, r: 20, b: 80, t: 40 }, // Adjust the bottom margin
          }}
          useResizeHandler
          style={{ width: "100%", height: "100%" }}
          config={{ displayModeBar: false }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default BacktestingGraph;
