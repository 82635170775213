import ReactApexChart from "react-apexcharts";
import React from 'react';
import dayjs from "dayjs";
/**
 * The `CandleStick` component renders a Candlestick chart using the ReactApexChart library.
 * It displays financial data with open, high, low, and close prices over time.
 *
 * This function is called in \src\Component\NewsAggregator\NewsAggregator.jsx
 * 
 * @param {Object} props - The component's props, including data for the Candlestick chart.
 * @returns {JSX.Element} The rendered `CandleStick` component.
 */
class CandleStick extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            series: [{
                name: 'candle',
                data: this.props.data,
            }],
            options: {
                chart: {
                    //height: 350,
                    type: 'candlestick',
                    toolbar: {
                        show: false,
                    },
                },
   
                annotations: {
                    xaxis: [
                        {
                            x: '',
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    fontSize: '12px',
                                    color: '#fff',
                                    background: '#00E396'
                                },
                                orientation: 'horizontal',
                                offsetY: 7,
                                // text: 'Annotation Test'
                                text: ''

                            }
                        }
                    ]
                },
                tooltip: {
                    enabled: true,
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        formatter: function (val) {
                            return dayjs(val).format('MMM DD')
                        },
                        rotate: -60,
                        rotateAlways: true,
                    }
                },
                yaxis: {
                    tooltip: {
                        enabled: true
                    }
                }
            },


        };
    }

    componentDidUpdate(prevProps, prevState) {
        // check whether this.props.data has changed 
        if (prevProps.data !== this.props.data) {
            const modifiedData = this.props.data;
            this.setState({ series: [{ name: 'candle', data: modifiedData, }] });
        }
    }


    //The xy format accepts [{ x: date, y: [O,H,L,C] }].



    //Candlestick Chart
    render() {
        return (

            <div className={`col-md-6 col-margin`}>
                <div className="outer-wraper">
                    <h6 className="graph-text-heading">Candlestick&nbsp;Chart</h6>
                    <div className="graph-container">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="candlestick" height={'100%'} />
                    </div>
                </div>
            </div>

        );
    }

}

export default CandleStick;