import { format, differenceInDays } from "date-fns";

//make max days between is 10 days ---> else graph wont fit
export const getDatesBetweenDates = (startDate, endDate) => {
  const dates = [];

  const date = new Date(startDate); //i.e --> new Date("2017-01-26"); -- if dont use 'new Date()' directly modifying date

  const endtDated = new Date(endDate);

  while (date <= endtDated) {
    dates.push(format(new Date(date), "yyyy-MM-dd")); //used date-fns since ant design timepicker uses it to compute date
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const getDifferenceInDays = (startDate, endDate) => {
  const result = differenceInDays(
    new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    ),
    new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
  );

  return result;
};

export const filterCandleStickData = (dataObject, allDates) => {
  const modifiedData = [];

  if (allDates?.length === 0) {
    //ignore
  } else if (Object.keys(dataObject || {})?.length === 0) {
    //ignore
  } else {
    for (let i = 0; i < allDates.length; i++) {
      const datedd = allDates[i];

      if (dataObject.Open[datedd] || dataObject.Open[datedd] === 0) {
        //dates in allDates with no data (i.e sat, sunday or holiday excluded here)
        //check for 0  --> since  0 is a false value in Javascript
        ///datedd  2023-06-02

        modifiedData.push({
          x: datedd,
          y: [
            dataObject.Open[datedd]
              ? Number(dataObject.Open[datedd]).toFixed(2)
              : dataObject.Open[datedd],
            //even if  dataObject.Open[datedd] = 0 value shown without formatting

            dataObject.High[datedd]
              ? Number(dataObject.High[datedd]).toFixed(2)
              : dataObject.High[datedd],

            dataObject.Low[datedd]
              ? Number(dataObject.Low[datedd]).toFixed(2)
              : dataObject.Low[datedd],

            dataObject.Close[datedd]
              ? Number(dataObject.Close[datedd]).toFixed(2)
              : dataObject.Close[datedd],
          ],
        });
      }
    }
  }

  return modifiedData;
};

export const filterNewsTabData = (data, dateRange) => {
  let arr = [];

  if (Object.keys(data || {})?.length === 0) {
    //ignore
  } else if (!dateRange || dateRange.length === 0) {
    // If dateRange is not provided or is empty, combine all data
    Object.keys(data).forEach((date) => {
      if (data[date]) {
        const currDateData = data[date]?.length > 0 ? data[date] : [];
        arr = [...arr, ...currDateData];
      }
    });
  } else {
    for (let step = 0; step < dateRange.length; step++) {
      const date = dateRange[step];
      if (data[date]) {
        const currDateData = data[date]?.length > 0 ? data[date] : [];
        arr = [...arr, ...currDateData];
      }
    }
  }

  return arr;
};

export const filterStackBarLine = (barGraphData, lineGraphData, dateRange) => {
  const valuesArr = [];

  const barGraphDataExists = Object.keys(barGraphData || {})?.length > 0;
  const lineGraphDataExists = Object.keys(lineGraphData || {})?.length > 0;

  const dates =
    dateRange && dateRange.length > 0
      ? dateRange
      : Array.from(
          new Set([
            ...Object.keys(barGraphData || {}),
            ...Object.keys(lineGraphData || {}),
          ])
        );

  for (let step = 0; step < dates.length; step++) {
    const date = dates[step];
    let tempObj = {};

    if (barGraphDataExists && barGraphData[date]) {
      tempObj = { ...tempObj, ...barGraphData[date] };
    }

    if (lineGraphDataExists && lineGraphData[date]) {
      tempObj = { ...tempObj, ...lineGraphData[date] };
    }

    // tempObj now  --> 6 properties exist
    // {
    //   dated: "2023-05-26",
    //   neg_count: 1,
    //   neutral_count: 15,
    //   pos_count: 33,
    //   x: "2023-05-26",
    //   y : 11
    // }

    if (Object.keys(tempObj || {})?.length > 5) {
      // 6 properties exist
      // also checks if date missing in barGraphData or lineGraphData
      valuesArr.push(tempObj);
    }
  }

  return valuesArr;
};

export const filterHeatMapData = (heatMapData, dateRange) => {
  const dataArr = [];

  if (Object.keys(heatMapData || {})?.length === 0) {
    //ignore
  } else {
    const subClasses = Object.keys(heatMapData);
    const dates =
      dateRange && dateRange.length > 0
        ? dateRange
        : Array.from(
            new Set(
              subClasses.flatMap((subClass) =>
                Object.keys(heatMapData[subClass])
              )
            )
          );

    dates.forEach((date) => {
      const subDataArr = [];

      subClasses.forEach((subClass) => {
        if (heatMapData[subClass][date] || heatMapData[subClass][date] === 0) {
          //check if data exists for that date --> i.e exclude sat-sun
          //also javascript considers 0 as false in if statement --> check for that too

          subDataArr.push({
            x: subClass,
            y: heatMapData[subClass][date],
          });
        }
      });

      if (subDataArr?.length > 0) {
        //check if data exists for that date --> i.e exclude sat-sun
        dataArr.push({
          name: format(new Date(date), "dd LLL"),
          data: subDataArr,
        });
      }
    });
  }

  return dataArr;
};

export const filterAndCombineNewsSourceDist = (allData, dateRange) => {
  const filteredAndCombinedData = combineAndFilterJsonObject(
    allData,
    dateRange
  );

  let finalDataObject = {}; //data in graph format
  if (Object.keys(filteredAndCombinedData || {})?.length === 0) {
    //ignore
  } else {
    finalDataObject = {
      x: Object.keys(filteredAndCombinedData),
      y: Object.values(filteredAndCombinedData),
    };
  }

  return finalDataObject;
};

export const filterFrequentEntities = (allData, dateRange) => {
  const filteredAndCombinedData = combineAndFilterJsonObject(
    allData,
    dateRange
  );

  let arr = []; //data in graph format
  if (Object.keys(filteredAndCombinedData || {})?.length === 0) {
    //ignore
  } else {
    for (const [key, value] of Object.entries(filteredAndCombinedData)) {
      arr.push({ x: key, y: value });
    }
  }

  //if length greater than 10 ---> then sort and take first 10
  return arr.length > 10
    ? [...arr].sort((a, b) => (a.y > b.y ? -1 : 1)).slice(0, 10)
    : arr;
};

//common function
const combineAndFilterJsonObject = (allData, dateRange) => {
  const filteredAndCombinedData = {};

  if (Object.keys(allData || {})?.length === 0) {
    //ignore
  } else if (!dateRange || dateRange.length === 0) {
    // If dateRange is not provided or is empty, combine all data
    Object.keys(allData).forEach((date) => {
      if (allData[date]) {
        const newSources = Object.keys(allData[date]); // news Sources for current date

        for (let step = 0; step < newSources.length; step++) {
          const sourceName = newSources[step];
          const sourceNewValue = allData[date][sourceName];

          if (
            sourceName &&
            (filteredAndCombinedData[sourceName] ||
              filteredAndCombinedData[sourceName] === 0) &&
            (sourceNewValue || sourceNewValue === 0)
          ) {
            // check if new source count exists in prev day and current day
            filteredAndCombinedData[sourceName] += sourceNewValue;
          } else if (sourceName && (sourceNewValue || sourceNewValue === 0)) {
            // doesn't exist in prev day
            filteredAndCombinedData[sourceName] = sourceNewValue;
          }
        }
      }
    });
  } else {
    dateRange.forEach((date) => {
      if (allData[date]) {
        const newSources = Object.keys(allData[date]); // news Sources for current date

        for (let step = 0; step < newSources.length; step++) {
          const sourceName = newSources[step];
          const sourceNewValue = allData[date][sourceName];

          if (
            sourceName &&
            (filteredAndCombinedData[sourceName] ||
              filteredAndCombinedData[sourceName] === 0) &&
            (sourceNewValue || sourceNewValue === 0)
          ) {
            // check if new source count exists in prev day and current day
            filteredAndCombinedData[sourceName] += sourceNewValue;
          } else if (sourceName && (sourceNewValue || sourceNewValue === 0)) {
            // doesn't exist in prev day
            filteredAndCombinedData[sourceName] = sourceNewValue;
          }
        }
      }
    });
  }

  return filteredAndCombinedData;
};


const companies = [
  {
    Stock_Ticker: "ASIANPAINT.NS",
    Company_Name: "Asian Paints Ltd",
    id: 1,
    Sector: "CONSUMER DURABLES",
  },
  {
    Stock_Ticker: "TATAMOTORS.NS",
    Company_Name: "Tata Motors Limited",
    id: 2,
    Sector: "AUTOMOBILE",
  },
  {
    Stock_Ticker: "BRITANNIA.NS",
    Company_Name: "Britannia Industries Ltd",
    id: 3,
    Sector: "FAST MOVING CONSUMER GOODS",
  },
  {
    Stock_Ticker: "APOLLOHOSP.NS",
    Company_Name: "Apollo Hospitals Enterprise Ltd",
    id: 4,
    Sector: "HEALTHCARE",
  },
  {
    Stock_Ticker: "COALINDIA.NS",
    Company_Name: "Coal India Ltd",
    id: 5,
    Sector: "OIL GAS AND CONSUMABLE FUEL",
  },
  {
    Stock_Ticker: "ICICIBANK.NS",
    Company_Name: "ICICI Bank Ltd",
    id: 6,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "HDFCBANK.NS",
    Company_Name: "HDFC Bank Ltd",
    id: 7,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "MARUTI.NS",
    Company_Name: "Maruti Suzuki Ltd",
    id: 8,
    Sector: "AUTOMOBILE",
  },
  {
    Stock_Ticker: "SUNPHARMA.NS",
    Company_Name: "Sun Pharmaceutical Industries Ltd",
    id: 9,
    Sector: "HEALTHCARE",
  },
  {
    Stock_Ticker: "BAJAJ-AUTO.NS",
    Company_Name: "Bajaj Auto Ltd",
    id: 10,
    Sector: "AUTOMOBILE",
  },
  {
    Stock_Ticker: "RELIANCE.NS",
    Company_Name: "Reliance Industries Ltd",
    id: 11,
    Sector: "OIL GAS AND CONSUMABLE FUEL",
  },
  {
    Stock_Ticker: "SCHAEFFLER.NS",
    Company_Name: "Schaeffler India Ltd",
    id: 12,
    Sector: "BEARINGS",
  },
  {
    Stock_Ticker: "HINDUNILVR.NS",
    Company_Name: "Hindustan Unilever Ltd",
    id: 13,
    Sector: "FAST MOVING CONSUMER GOODS",
  },
  {
    Stock_Ticker: "EICHERMOT.NS",
    Company_Name: "Eicher Motors Ltd",
    id: 14,
    Sector: "AUTOMOBILE",
  },
  {
    Stock_Ticker: "ADANIPORTS.NS",
    Company_Name: "Adani Ports and Special Economic Zone Ltd",
    id: 15,
    Sector: "SERVICES",
  },
  {
    Stock_Ticker: "HAL.NS",
    Company_Name: "Hindustan Aeronautics Ltd",
    id: 16,
    Sector: "CAPITAL GOODS-NON ELECTRICAL EQUIPMENT",
  },
  {
    Stock_Ticker: "DRREDDY.NS",
    Company_Name: "Dr. Reddy's Laboratories Ltd",
    id: 17,
    Sector: "HEALTHCARE",
  },
  {
    Stock_Ticker: "HCLTECH.NS",
    Company_Name: "HCL Ltd",
    id: 18,
    Sector: "IT",
  },
  {
    Stock_Ticker: "ADANIENT.NS",
    Company_Name: "Adani Enterprises Ltd",
    id: 19,
    Sector: "CONGLOMERATE",
  },
  {
    Stock_Ticker: "PERSISTENT.NS",
    Company_Name: "Persistent Systems Ltd",
    id: 20,
    Sector: "IT",
  },
  {
    Stock_Ticker: "POWERGRID.NS",
    Company_Name: "Power Grid Corporation of India Ltd",
    id: 21,
    Sector: "POWER",
  },
  {
    Stock_Ticker: "GRASIM.NS",
    Company_Name: "Grasim Industries Ltd",
    id: 22,
    Sector: "CONSTRUCTION MATERIALS",
  },
  {
    Stock_Ticker: "TATACONSUM.NS",
    Company_Name: "Tata Consumer Ltd",
    id: 23,
    Sector: "FAST MOVING CONSUMER GOODS",
  },
  {
    Stock_Ticker: "ONGC.NS",
    Company_Name: "Oil & Natural Gas Corporation Ltd",
    id: 24,
    Sector: "OIL GAS AND CONSUMABLE FUEL",
  },
  {
    Stock_Ticker: "LT.NS",
    Company_Name: "Larsen & Toubro Ltd",
    id: 25,
    Sector: "CONSTRUCTION",
  },
  {
    Stock_Ticker: "ITC.NS",
    Company_Name: "ITC Ltd",
    id: 26,
    Sector: "FAST MOVING CONSUMER GOODS",
  },
  {
    Stock_Ticker: "AXISBANK.NS",
    Company_Name: "Axis Bank Ltd",
    id: 27,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "INFY.NS",
    Company_Name: "Infosys Ltd",
    id: 28,
    Sector: "IT",
  },
  {
    Stock_Ticker: "KPITTECH.NS",
    Company_Name: "KPIT Technologies Ltd",
    id: 29,
    Sector: "IT",
  },
  {
    Stock_Ticker: "JSWSTEEL.NS",
    Company_Name: "JSW Steel Ltd",
    id: 30,
    Sector: "METALS AND MINING",
  },
  {
    Stock_Ticker: "TITAN.NS",
    Company_Name: "Titan Company Ltd",
    id: 31,
    Sector: "CONSUMER DURABLES",
  },
  {
    Stock_Ticker: "HINDALCO.NS",
    Company_Name: "Hindalco Industries Ltd",
    id: 32,
    Sector: "METALS AND MINING",
  },
  {
    Stock_Ticker: "NESTLEIND.NS",
    Company_Name: "Nestle India Ltd",
    id: 33,
    Sector: "FAST MOVING CONSUMER GOODS",
  },
  {
    Stock_Ticker: "TCS.NS",
    Company_Name: "Tata Consultancy Services Ltd",
    id: 34,
    Sector: "IT",
  },
  {
    Stock_Ticker: "BHARTIARTL.NS",
    Company_Name: "Bharti Airtel Ltd",
    id: 35,
    Sector: "TELECOMMUNICATION",
  },
  {
    Stock_Ticker: "BAJFINANCE.NS",
    Company_Name: "Bajaj Finance Ltd",
    id: 36,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "CIPLA.NS",
    Company_Name: "Cipla Ltd",
    id: 37,
    Sector: "HEALTHCARE",
  },
  {
    Stock_Ticker: "SOLARINDS.NS",
    Company_Name: "Solar Industries India Ltd",
    id: 38,
    Sector: "CHEMICALS",
  },
  {
    Stock_Ticker: "UPL.NS",
    Company_Name: "UPL Ltd",
    id: 39,
    Sector: "CHEMICALS",
  },
  {
    Stock_Ticker: "DIVISLAB.NS",
    Company_Name: "Divi's Laboratories Ltd",
    id: 40,
    Sector: "HEALTHCARE",
  },
  {
    Stock_Ticker: "TIINDIA.NS",
    Company_Name: "Tube Investments of India Ltd",
    id: 41,
    Sector: "AUTO ANCILLARIES",
  },
  {
    Stock_Ticker: "M&M.NS",
    Company_Name: "Mahindra & Mahindra Limited",
    id: 42,
    Sector: "AUTOMOBILE",
  },
  {
    Stock_Ticker: "ULTRACEMCO.NS",
    Company_Name: "UltraTech Cement Ltd",
    id: 43,
    Sector: "CONSTRUCTION MATERIALS",
  },
  {
    Stock_Ticker: "MAXIND.NS",
    Company_Name: "Max India Ltd",
    id: 44,
    Sector: "MISCELLANEOUS",
  },
  {
    Stock_Ticker: "KOTAKBANK.NS",
    Company_Name: "Kotak Mahindra Bank Ltd",
    id: 45,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "BAJAJFINSV.NS",
    Company_Name: "Bajaj Finserv Ltd",
    id: 46,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "BPCL.NS",
    Company_Name: "Bharat Petroleum Corporation Ltd",
    id: 47,
    Sector: "OIL GAS AND CONSUMABLE FUEL",
  },
  {
    Stock_Ticker: "WIPRO.NS",
    Company_Name: "Wipro Ltd",
    id: 48,
    Sector: "IT",
  },
  {
    Stock_Ticker: "HDFCLIFE.NS",
    Company_Name: "HDFC Life Insurance Company Ltd",
    id: 49,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "TATASTEEL.NS",
    Company_Name: "Tata Steel Ltd",
    id: 50,
    Sector: "METALS AND MINING",
  },
  {
    Stock_Ticker: "TECHM.NS",
    Company_Name: "Tech Mahindra Ltd",
    id: 51,
    Sector: "IT",
  },
  {
    Stock_Ticker: "INDUSINDBK.NS",
    Company_Name: "IndusInd Bank Ltd",
    id: 52,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "SBIN.NS",
    Company_Name: "State Bank of India",
    id: 53,
    Sector: "FINANCIAL SERVICES",
  },
  {
    Stock_Ticker: "NTPC.NS",
    Company_Name: "NTPC Ltd",
    id: 54,
    Sector: "POWER",
  },
  {
    Stock_Ticker: "HEROMOTOCO.NS",
    Company_Name: "Hero Motocorp Ltd",
    id: 55,
    Sector: "AUTOMOBILE",
  },
  {
    Stock_Ticker: "SBILIFE.NS",
    Company_Name: "SBI Life Insurance Company Ltd",
    id: 56,
    Sector: "FINANCIAL SERVICES",
  },
];

export const getAllNewsAggOptionsData = () => {
  return companies.map((company) => ({
    value: company.Stock_Ticker,
    label: company.Company_Name,
  }));
};
