import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import "./header.scss";
import backArrow from "../assets/images/icons/back_arrow.svg";
import logoHorizontal from "../assets/images/logo_horizontal.svg";
import locationIcon from "../assets/images/icons/location.svg";
import searchIcon from "../assets/images/icons/blue_search.svg";
import { useNavigate, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { summary } from "../Actions/summary";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { setSession } from "../root_reducer";
import useWindowDimemsions from "../hooks/useWindowDimesnions";



const arry = ["/search", "/detail", "/aggregator"];
/**
 * React component representing the header of the application.
 * The header contains navigation links, filters, and logo.
 *
 * This function is called in \src\Router\Router.tsx
 * 
 * @returns {JSX.Element} The JSX representation of the Header component.
 */
const Header = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const headerFilter = useSelector((state: any) => state.FilterSummary.filter);

  //const user = useSelector((state) => (state as any).user_authentication);

  //console.log("USER");
  //console.log(user.username);

  // const [age, setAge] = React.useState("");

  const dispatch = useDispatch();
  const { width }:any = useWindowDimemsions();

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(summary(event.target.value));
    //setAge(event.target.value);
  };

  function logOut() {
    dispatch(setSession(false));
    navigate("/login");
  }

  const lgWidth = 991;

  const algoLogo = () => {
    return (
      <img
        src={logoHorizontal}
        alt="logoHorizontal"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
    );
  }


  //search icon function
  const searchSvg = () => {

    //return null;    //if you want to remove search uncomment this

    //if you want to remove search comment this (i.e both below return statements)
    if (location.pathname === "/search") return null;

    return (location.pathname !== "/" && location.pathname !== "/detail" ?
    
      <img
        onClick={() => {
          navigate("/search")
        }}
        src={searchIcon}
        alt="Search Data"
        style={{
          width: "21px",
          cursor: "pointer"
        }}
      />

      : null
    )
  }


  const searchSvgBreaker = 622; //same used for alogoLogo
  const styleObj = width && width > lgWidth ? {} : { fontSize: "small" };
  const listItemStyle = width && width > lgWidth ? {} : { fontSize: "small", minHeight: '2px !important' };

  return (
    <div className="header">
      <Box sx={{ flexGrow: 1 }}>


        <AppBar
          position="static"
          style={{
            background: "#ffff",
          }}
        >
          {width && width < searchSvgBreaker &&
            <div
              style={{ background: 'white', textAlign: 'center' }}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="logo-img">
                {algoLogo()}
              </div>

              <div className="logo-img">
                {searchSvg()}
              </div>

            </div>
          }

          <Toolbar className="toolbar-flex">
            {arry.includes(location.pathname) && (
              <Button
                variant="outlined"
                className="backButton"
                onClick={() => navigate("/dashboard")}
              >
                <img src={backArrow} alt="backArrow" /> &nbsp; Dashboard
              </Button>
            )}

            {location.pathname === "/dashboard" && (
              <FormControl
                sx={{ m: 1, minWidth: 200 }}
                className="header-select"
              >
                <Select
                  value={headerFilter}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ ...styleObj, p: 0 }}
                //renderValue={headerFilter ||  headerFilter==="" ? undefined : ()=>"Please select"}
                >
                  <MenuItem value="All" sx={listItemStyle} >All Sectors</MenuItem>
                  {/* <MenuItem value="BANK">Banking</MenuItem> */}
                  <MenuItem value="AUTO ANCILLARIES" sx={listItemStyle} >Auto Ancillaries</MenuItem>
                  <MenuItem value="AUTOMOBILE" sx={listItemStyle} >Automobile</MenuItem>
                  <MenuItem value="BEARINGS" sx={listItemStyle} >Bearings</MenuItem>
                  <MenuItem value="CAPITAL GOODS-NON ELECTRICAL EQUIPMENT" sx={listItemStyle} >Capital Goods-Non Electrical Equipment</MenuItem>
                  <MenuItem value="CHEMICALS" sx={listItemStyle} >Chemicals</MenuItem>
                  <MenuItem value="CONGLOMERATE" sx={listItemStyle} >Conglomerate</MenuItem>
                  <MenuItem value="CONSTRUCTION" sx={listItemStyle} >Construction</MenuItem>
                  <MenuItem value="CONSTRUCTION MATERIALS" sx={listItemStyle} >Construction Materials</MenuItem>
                  <MenuItem value="CONSUMER DURABLES" sx={listItemStyle}>Consumer Durables</MenuItem>
                  <MenuItem value="FINANCIAL SERVICES" sx={listItemStyle} >Financial Services</MenuItem>
                  <MenuItem value="FAST MOVING CONSUMER GOODS" sx={listItemStyle} >FMCG</MenuItem>
                  <MenuItem value="HEALTHCARE" sx={listItemStyle} >Healthcare</MenuItem>
                  <MenuItem value="IT" sx={listItemStyle} >IT</MenuItem>
                  <MenuItem value="MISCELLANEOUS" sx={listItemStyle} >Miscellaneous</MenuItem>
                  <MenuItem value="METALS AND MINING" sx={listItemStyle}>Metals and Mining</MenuItem>
                  <MenuItem value="OIL GAS AND CONSUMABLE FUEL" sx={listItemStyle}>Oil Gas and Consumable Fuel</MenuItem>
                  <MenuItem value="POWER" sx={listItemStyle} >Power</MenuItem>
                  <MenuItem value="SERVICES" sx={listItemStyle} >Services</MenuItem>
                  <MenuItem value="TELECOMMUNICATION" sx={listItemStyle} >Telecommunication</MenuItem>
                  {headerFilter === "" && <MenuItem disabled value="" sx={listItemStyle}>
                    <span style={{ opacity: '0.5', fontWeight: '500', color: 'gray' }}>Select Sector</span>
                  </MenuItem>}
                </Select>
              </FormControl>
            )}


            {width && width >= searchSvgBreaker && algoLogo()}

            {location.pathname !== "/" && location.pathname !== "/detail" &&
              (
                <div className="d-flex align-items-center">

                  <div >
                    {width && width >= searchSvgBreaker && searchSvg()}
                  </div>


                  <div>
                    <Button
                      sx={styleObj}
                      variant="contained"
                      onClick={() =>
                        window.open(
                          "http://eepurl.com/ibIOOL",
                          "Popup",
                          "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
                        )
                      }
                      className="submit-button ms-4"
                    >
                      {width && width >= 380 ? "Newsletter" : ""}&nbsp;{" "}
                      <img
                        src="/assets/images/icons/icons8-bell-32.png"   //367
                        alt="submit"
                      />
                    </Button>
                  </div>




                  {/* <Nav>
                <NavDropdown title={user.username}>
                  <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav> */}
                  {/* <Button variant="contained" onClick={logOut} className="submit-button ms-4">
                Logout
              </Button> */}
                </div>
              )
            }


          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Header;

